export interface TipoDataI {
  codigo: number;
  tipo: string;
  estado: number;
}

export class Tipo implements TipoDataI {
  codigo: number;
  tipo: string;
  estado: number;

  constructor(data: TipoDataI) {
    this.codigo = data.codigo;
    this.tipo = data.tipo;
    this.estado = data.estado;
  }
}
