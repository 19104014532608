import { _getSession } from "./dashboard.page";
import { APIArea } from "../../api/api_area";
import { APIResponse } from "../../model/apiresponse";
import { Area } from "../../model/area";
import { Breadcrumb } from "antd";
import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { Col } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { EditOutlined } from "@ant-design/icons";
import { FilterOutlined } from "@ant-design/icons";
import { Fragment } from "react";
import { Input } from "antd";
import { Modal } from "antd";
import { notification } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ReloadOutlined } from "@ant-design/icons";
import { Row } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Select } from "antd";
import { Space } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import { useCallback } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import AreaContext from "../../provider/area-provider";
import AreasEditarWidget from "../widgets/areas.editar.widget";
import AreasListarWidget from "../widgets/areas.listar.widget";
import AreasRegistrarWidget from "../widgets/areas.registrar.widget";
import ErrorComponent from "../components/error.component";
import Global from "../../global/global";
import LoadComponent from "../components/load.component";

function AreasPage() {
  const [_area, _setArea] = useState<string>("");
  const [_areaEditar, _setAreaEditar] = useState<Area>();
  const [_areas, _setAreas] = useState<Area[]>([]);
  const [_areasTemp, _setAreasTemp] = useState<Area[]>([]);
  const [_loading, _setLoading] = useState<boolean>(true);
  const [_modal, _contextHolder] = Modal.useModal();
  const [_mounted, _setMounted] = useState<any>(null);
  const [_visibleEditar, _setVisibleEditar] = useState<boolean>(false);
  const [_visibleNuevo, _setVisibleNuevo] = useState<boolean>(false);
  const _apiArea = useContext<APIArea>(AreaContext);
  const { _token } = _getSession();
  const { Title } = Typography;
  const { Option } = Select;
  let _navigate = useNavigate();

  const _listarAreas = useCallback(async () => {
    _setLoading(true);
    _setAreas([]);
    _setAreasTemp([]);
    let _result: APIResponse = await _apiArea._listarAreas(_token, 2);
    if (_result.codigo === 401) {
      _modal.error({
        title: Global.NOMBRE_PROYECTO,
        content: _result.message,
        centered: true,
        onOk: () => {
          localStorage.clear();
          _navigate("/login");
        },
      });
    } else if (_result.success) {
      _setAreas(_result.data);
      _setAreasTemp(_result.data);
    } else {
      _modal.error({
        title: Global.NOMBRE_PROYECTO,
        content: _result.message,
        centered: true,
      });
      _setAreas([]);
      _setAreasTemp([]);
    }

    _setLoading(false);
  }, [_apiArea, _modal, _navigate, _token]);

  const _filtrarEstado = () => ({
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
        <Select
          placeholder="Selecciona un estado"
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(_input: any, _option: any) =>
            _option.children.toLowerCase().indexOf(_input.toLowerCase()) >= 0
          }
          filterSort={(_optionA: any, _optionB: any) =>
            _optionA.children
              .toLowerCase()
              .localeCompare(_optionB.children.toLowerCase())
          }
          style={{ width: "250px" }}
          onChange={(_value: number, _option: any) => {
            if (_value) {
              let _areasFound: Area[] = [];
              for (let i = 0; i < _areasTemp.length; i++) {
                if (_areasTemp[i].estado === _value) {
                  _areasFound.push(_areasTemp[i]);
                }
              }
              _setAreas(_areasFound);
            } else {
              _setAreas(_areasTemp);
            }
          }}
        >
          <Option key="estado_activo" value="1">
            Activo
          </Option>
          <Option key="estado_inactivo" value="0">
            Inactivo
          </Option>
        </Select>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <FilterOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  const _buscarArea = () => ({
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
        <Input
          value={_area}
          onChange={(e) => _setArea(e.target.value)}
          onPressEnter={() => _buscar(_area)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            onClick={() => _buscar(_area)}
            icon={<SearchOutlined />}
            size="small"
            className="button-info"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => _limpiar()}
            icon={<CloseOutlined />}
            size="small"
            danger
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  const _buscar = (_area: string) => {
    if (_area.length > 0) {
      let _areasFound: Area[] = [];
      for (let i = 0; i < _areasTemp.length; i++) {
        if (
          _areasTemp[i].area.toUpperCase().indexOf(_area.toUpperCase()) > -1
        ) {
          _areasFound.push(_areasTemp[i]);
        }
      }
      _setAreas(_areasFound);
    }
  };

  const _limpiar = () => {
    _setArea("");
    _setAreas(_areasTemp);
  };

  const _renderEstado = (_value: number, _row: Area, _index: number) => {
    return (
      <Space>
        <div
          key={`switch${_row.codigo}`}
          className={_value === 1 ? "div-status-active" : "div-status-inactive"}
        />
      </Space>
    );
  };

  const _renderAcciones = (_value: number, _row: Area, _index: number) => {
    return _value === 1 ? (
      <Space>
        <EditOutlined
          className="icon-edit"
          onClick={() => {
            _setAreaEditar(_row);
            _abrirEditar();
          }}
        />
        <DeleteOutlined
          className="icon-delete"
          onClick={() => {
            _cambiarEstadoArea(_row.codigo, 0);
          }}
        />
      </Space>
    ) : (
      <Space>
        <SyncOutlined
          className="icon-edit"
          onClick={() => {
            _cambiarEstadoArea(_row.codigo, 1);
          }}
        />
      </Space>
    );
  };

  const _cambiarEstadoArea = useCallback(
    async (_codigo: number, _estado: number) => {
      let _result: APIResponse = await _apiArea._cambiarEstadoArea(
        _codigo,
        _estado,
        _token
      );
      if (_result.success) {
        notification.success({
          message: Global.NOMBRE_PROYECTO,
          description: _result.message,
          duration: 2,
        });
      } else {
        notification.error({
          message: Global.NOMBRE_PROYECTO,
          description: _result.message,
          duration: 2,
        });
      }
      _listarAreas();
    },
    [_apiArea, _listarAreas, _token]
  );

  const _abrirNuevo = () => {
    _setVisibleNuevo(true);
  };

  const _cerrarNuevo = () => {
    _setVisibleNuevo(false);
  };

  const _abrirEditar = () => {
    _setVisibleEditar(true);
  };

  const _cerrarEditar = () => {
    _setVisibleEditar(false);
  };

  useEffect(() => {
    _setMounted(true);
    _listarAreas();
    return () => {
      _setMounted(false);
    };
  }, [_listarAreas]);

  if (_mounted === null) {
    return <LoadComponent />;
  }

  if (!_mounted) {
    return <ErrorComponent />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Breadcrumb>
            <Breadcrumb.Item>Inicio</Breadcrumb.Item>
            <Breadcrumb.Item>
              <a href="/areas">&Aacute;reas</a>
            </Breadcrumb.Item>
          </Breadcrumb>
          <hr />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Title level={3}>&Aacute;reas</Title>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className="mb-10">
          <Space>
            <Button
              icon={<PlusOutlined />}
              className="button-success"
              onClick={_abrirNuevo}
            >
              Agregar
            </Button>
            <Button
              icon={<ReloadOutlined />}
              className="button-info"
              onClick={() => {
                _listarAreas();
              }}
            >
              Recargar
            </Button>
          </Space>
        </Col>
        {/* Lista de áreas */}
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <AreasListarWidget
            _areas={_areas}
            _buscarArea={_buscarArea}
            _loading={_loading}
            _renderAcciones={_renderAcciones}
            _renderEstado={_renderEstado}
            _filtrarEstado={_filtrarEstado}
          />
        </Col>
      </Row>
      {/* Nuevo registro */}
      <Modal
        title={<b>Nuevo registro</b>}
        visible={_visibleNuevo}
        centered={true}
        closable={false}
        footer={[]}
      >
        <AreasRegistrarWidget
          _apiArea={_apiArea}
          _cerrarNuevo={_cerrarNuevo}
          _listarAreas={_listarAreas}
          _token={_token}
        />
      </Modal>
      {/* Editar registro */}
      <Modal
        title={<b>Editar registro</b>}
        visible={_visibleEditar}
        centered={true}
        closable={false}
        footer={[]}
      >
        <AreasEditarWidget
          _apiArea={_apiArea}
          _areaEditar={_areaEditar!}
          _cerrarEditar={_cerrarEditar}
          _listarAreas={_listarAreas}
          _token={_token}
        />
      </Modal>
      {_contextHolder}
    </Fragment>
  );
}

export default AreasPage;
