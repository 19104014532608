export interface AreaDataI {
  codigo: number;
  area: string;
  estado: number;
}

export class Area implements AreaDataI {
  codigo: number;
  area: string;
  estado: number;

  constructor(data: AreaDataI) {
    this.codigo = data.codigo;
    this.area = data.area;
    this.estado = data.estado;
  }
}
