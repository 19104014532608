import axios from "axios";
import Global from "../global/global";
import { APIResponse } from "../model/apiresponse";
import { Proceso } from "../model/proceso";
import { ProcesoDataI } from "../model/proceso";

export class APIProceso {
  async _listarProcesos(_token: string, _estado: number): Promise<APIResponse> {
    let _response: APIResponse = new APIResponse({});
    try {
      let _result: any = await axios.post(`${Global.URL_API}listarProcesos`, {
        token: _token,
        estado: _estado,
      });
      if (_result.status === 200) {
        if (_result.data.success) {
          let _procesos: Proceso[] = [];
          _result.data.data.forEach((_proceso: ProcesoDataI) => {
            _procesos.push(new Proceso(_proceso));
          });
          _response = {
            success: _result.data.success,
            data: _procesos,
            codigo: _result.data.codigo,
          };
        } else {
          _response = {
            success: _result.data.success,
            message: _result.data.message,
            codigo: _result.data.codigo,
          };
        }
      } else {
        _response = {
          success: false,
          message: "Ocurrió un error de conexión, inténtalo nuevamente",
          codigo: _result.data.codigo,
        };
      }
    } catch (e: any) {
      _response = {
        success: false,
        message: e,
      };
    }
    return _response;
  }

  async _cambiarEstadoProceso(
    _codigo: number,
    _estado: number,
    _token: string
  ): Promise<APIResponse> {
    let _response: APIResponse = new APIResponse({});
    try {
      let _result: any = await axios.put(
        `${Global.URL_API}cambiarEstadoProceso`,
        {
          codigo: _codigo,
          estado: _estado,
          token: _token,
        }
      );
      if (_result.status === 200) {
        _response = {
          success: _result.data.success,
          message: _result.data.message,
          codigo: _result.data.codigo,
        };
      } else {
        _response = {
          success: false,
          message: "Ocurrió un error de conexión, inténtalo nuevamente",
          codigo: _result.data.codigo,
        };
      }
    } catch (e: any) {
      _response = {
        success: false,
        message: e,
      };
    }
    return _response;
  }

  async _registrarProceso(
    _proceso: string,
    _area: number,
    _token: string
  ): Promise<APIResponse> {
    let _response: APIResponse = new APIResponse({});
    try {
      let _result: any = await axios.post(`${Global.URL_API}registrarProceso`, {
        proceso: _proceso,
        area: _area,
        token: _token,
      });
      if (_result.status === 200) {
        _response = {
          success: _result.data.success,
          message: _result.data.message,
          codigo: _result.data.codigo,
        };
      } else {
        _response = {
          success: false,
          message: "Ocurrió un error de conexión, inténtalo nuevamente",
          codigo: _result.data.codigo,
        };
      }
    } catch (e: any) {
      _response = {
        success: false,
        message: e,
      };
    }
    return _response;
  }

  async _editarProceso(
    _codigo: number,
    _proceso: string,
    _area: number,
    _token: string
  ): Promise<APIResponse> {
    let _response: APIResponse = new APIResponse({});
    try {
      let _result: any = await axios.put(`${Global.URL_API}editarProceso`, {
        area: _area,
        codigo: _codigo,
        proceso: _proceso,
        token: _token,
      });
      if (_result.status === 200) {
        _response = {
          success: _result.data.success,
          message: _result.data.message,
          codigo: _result.data.codigo,
        };
      } else {
        _response = {
          success: false,
          message: "Ocurrió un error de conexión, inténtalo nuevamente",
          codigo: _result.data.codigo,
        };
      }
    } catch (e: any) {
      _response = {
        success: false,
        message: e,
      };
    }
    return _response;
  }
}
