import { Col } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { EditOutlined } from "@ant-design/icons";
import { Fragment } from "react";
import { Proceso } from "../../model/proceso";
import { Row } from "antd";
import { Space } from "antd";
import { Subproceso } from "../../model/subproceso";
import { SyncOutlined } from "@ant-design/icons";
import { Table } from "antd";

function SubprocesosListarWidget({
  _subprocesos,
  _buscarSubproceso,
  _loading,
  _renderAcciones,
  _renderEstado,
  _filtrarEstado,
  _filtrarProceso,
  _filtrarArea,
}: {
  _subprocesos: Subproceso[];
  _buscarSubproceso: Function;
  _loading: boolean;
  _renderAcciones: Function;
  _renderEstado: Function;
  _filtrarEstado: Function;
  _filtrarProceso: Function;
  _filtrarArea: Function;
}) {
  return (
    <Fragment>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className="mb-10">
          Leyenda (Acciones):&nbsp;&nbsp;
          <Space>
            <EditOutlined className="icon-edit" /> Editar&nbsp;&nbsp;
            <DeleteOutlined className="icon-delete" /> Borrar&nbsp;&nbsp;
            <SyncOutlined className="icon-edit" /> Activar&nbsp;&nbsp;
          </Space>
          <br />
          Leyenda (Estado):&nbsp;&nbsp;
          <Space>
            <div className="div-status-active" /> Activo&nbsp;&nbsp;
            <div className="div-status-inactive" /> Inactivo&nbsp;&nbsp;
          </Space>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Table
            bordered
            key="subprocesos"
            size="small"
            rowKey="codigo"
            columns={[
              {
                title: "Estado",
                dataIndex: "estado",
                key: "estado",
                width: "5%",
                align: "center",
                ..._filtrarEstado(),
                render: _renderEstado,
                sorter: (a: Subproceso, b: Subproceso) => a.estado - b.estado,
              },
              {
                title: "Subproceso",
                dataIndex: "subproceso",
                key: "subproceso",
                width: "35%",
                align: "center",
                ..._buscarSubproceso(),
                sorter: (a: Subproceso, b: Subproceso) =>
                  a.subproceso.localeCompare(b.subproceso),
              },
              {
                title: "Proceso",
                dataIndex: "proceso",
                key: "proceso",
                width: "20%",
                align: "center",
                ..._filtrarProceso(),
                sorter: (a: Subproceso, b: Subproceso) =>
                  a.proceso.proceso.localeCompare(b.proceso.proceso),
                render: (_value: Proceso, _row: Subproceso, _index: number) => {
                  return _value.proceso;
                },
              },
              {
                title: "Área",
                dataIndex: "proceso",
                key: "proceso",
                width: "20%",
                align: "center",
                ..._filtrarArea(),
                sorter: (a: Subproceso, b: Subproceso) =>
                  a.proceso.area.area.localeCompare(b.proceso.area.area),
                render: (_value: Proceso, _row: Subproceso, _index: number) => {
                  return _value.area.area;
                },
              },
              {
                title: "Acciones",
                dataIndex: "estado",
                key: "estado",
                width: "10%",
                align: "center",
                render: _renderAcciones,
              },
            ]}
            pagination={{
              pageSize: 20,
              simple: false,
            }}
            loading={_loading}
            dataSource={_subprocesos.map((proceso: Subproceso) => ({
              ...proceso,
            }))}
          />
        </Col>
      </Row>
    </Fragment>
  );
}

export default SubprocesosListarWidget;
