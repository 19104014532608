import axios from "axios";
import Global from "../global/global";
import { APIResponse } from "../model/apiresponse";
import { Documento } from "../model/documento";
import { DocumentoDataI } from "../model/documento";

export class APIDocumento {
  async _listarDocumentos(
    _token: string,
    _estado: string
  ): Promise<APIResponse> {
    let _response: APIResponse = new APIResponse({});
    try {
      let _result: any = await axios.post(`${Global.URL_API}listarDocumentos`, {
        token: _token,
        estado: _estado,
      });
      if (_result.status === 200) {
        if (_result.data.success) {
          let _documentos: Documento[] = [];
          _result.data.data.forEach((_documento: DocumentoDataI) => {
            _documentos.push(new Documento(_documento));
          });
          _response = {
            success: _result.data.success,
            data: _documentos,
            codigo: _result.data.codigo,
          };
        } else {
          _response = {
            success: _result.data.success,
            message: _result.data.message,
            codigo: _result.data.codigo,
          };
        }
      } else {
        _response = {
          success: false,
          message: "Ocurrió un error de conexión, inténtalo nuevamente",
          codigo: _result.data.codigo,
        };
      }
    } catch (e: any) {
      _response = {
        success: false,
        message: e,
      };
    }
    return _response;
  }

  async _subirArchivo(_data: FormData): Promise<APIResponse> {
    let _response: APIResponse = new APIResponse({});
    try {
      let _result: any = await axios.post(
        `${Global.URL_API}subirArchivo`,
        _data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (_result.status === 200) {
        _response = {
          success: _result.data.success,
          message: _result.data.message,
          codigo: _result.data.codigo,
        };
      } else {
        _response = {
          success: false,
          message: "Ocurrió un error de conexión, inténtalo nuevamente",
          codigo: _result.data.codigo,
        };
      }
    } catch (e: any) {
      _response = {
        success: false,
        message: e,
      };
    }
    return _response;
  }

  async _registrarDocumento(_data: any, _token: string): Promise<APIResponse> {
    let _response: APIResponse = new APIResponse({});
    try {
      let _result: any = await axios.post(
        `${Global.URL_API}registrarDocumento`,
        {
          codigo_documento: _data["codigo"],
          version_documento: _data["version"],
          nombre_documento: _data["nombre"],
          vigencia_documento: _data["vigencia"],
          tipo_documento: _data["tipo"],
          subproceso_documento: _data["subproceso"],
          empresa_documento: _data["empresa"],
          lista_archivos: _data["archivos"],
          token: _token,
        }
      );
      if (_result.status === 200) {
        _response = {
          success: _result.data.success,
          message: _result.data.message,
          codigo: _result.data.codigo,
        };
      } else {
        _response = {
          success: false,
          message: "Ocurrió un error de conexión, inténtalo nuevamente",
          codigo: _result.data.codigo,
        };
      }
    } catch (e: any) {
      _response = {
        success: false,
        message: e,
      };
    }
    return _response;
  }

  async _editarDocumento(
    _data: any,
    _codigo: number,
    _token: string
  ): Promise<APIResponse> {
    let _response: APIResponse = new APIResponse({});
    try {
      let _result: any = await axios.put(`${Global.URL_API}editarDocumento`, {
        id_documento: _codigo,
        codigo_documento: _data["codigo"],
        version_documento: _data["version"],
        nombre_documento: _data["nombre"],
        vigencia_documento: _data["vigencia"],
        tipo_documento: _data["tipo"],
        subproceso_documento: _data["subproceso"],
        empresa_documento: _data["empresa"],
        lista_archivos: _data["archivos"],
        token: _token,
      });
      if (_result.status === 200) {
        _response = {
          success: _result.data.success,
          message: _result.data.message,
          codigo: _result.data.codigo,
        };
      } else {
        _response = {
          success: false,
          message: "Ocurrió un error de conexión, inténtalo nuevamente",
          codigo: _result.data.codigo,
        };
      }
    } catch (e: any) {
      _response = {
        success: false,
        message: e,
      };
    }
    return _response;
  }

  async _marcarDocumento(
    _usuario: number,
    _documento: number,
    _token: string
  ): Promise<APIResponse> {
    let _response: APIResponse = new APIResponse({});
    try {
      let _result: any = await axios.post(`${Global.URL_API}marcarDocumento`, {
        documento: _documento,
        usuario: _usuario,
        token: _token,
      });
      if (_result.status === 200) {
        _response = {
          success: _result.data.success,
          message: _result.data.message,
          codigo: _result.data.codigo,
        };
      } else {
        _response = {
          success: false,
          message: "Ocurrió un error de conexión, inténtalo nuevamente",
          codigo: _result.data.codigo,
        };
      }
    } catch (e: any) {
      _response = {
        success: false,
        message: e,
      };
    }
    return _response;
  }

  async _cambiarEstadoDocumento(
    _codigo: number,
    _estado: string,
    _token: string
  ): Promise<APIResponse> {
    let _response: APIResponse = new APIResponse({});
    try {
      let _result: any = await axios.put(
        `${Global.URL_API}cambiarEstadoDocumento`,
        {
          codigo: _codigo,
          estado: _estado,
          token: _token,
        }
      );
      if (_result.status === 200) {
        _response = {
          success: _result.data.success,
          message: _result.data.message,
          codigo: _result.data.codigo,
        };
      } else {
        _response = {
          success: false,
          message: "Ocurrió un error de conexión, inténtalo nuevamente",
          codigo: _result.data.codigo,
        };
      }
    } catch (e: any) {
      _response = {
        success: false,
        message: e,
      };
    }
    return _response;
  }
}
