import axios from "axios";
import Global from "../global/global";
import { APIResponse } from "../model/apiresponse";
import { Subproceso } from "../model/subproceso";
import { SubprocesoDataI } from "../model/subproceso";

export class APISubproceso {
  async _listarSubprocesos(
    _token: string,
    _estado: number
  ): Promise<APIResponse> {
    let _response: APIResponse = new APIResponse({});
    try {
      let _result: any = await axios.post(
        `${Global.URL_API}listarSubprocesos`,
        {
          token: _token,
          estado: _estado,
        }
      );
      if (_result.status === 200) {
        if (_result.data.success) {
          let _subprocesos: Subproceso[] = [];
          _result.data.data.forEach((_proceso: SubprocesoDataI) => {
            _subprocesos.push(new Subproceso(_proceso));
          });
          _response = {
            success: _result.data.success,
            data: _subprocesos,
            codigo: _result.data.codigo,
          };
        } else {
          _response = {
            success: _result.data.success,
            message: _result.data.message,
            codigo: _result.data.codigo,
          };
        }
      } else {
        _response = {
          success: false,
          message: "Ocurrió un error de conexión, inténtalo nuevamente",
          codigo: _result.data.codigo,
        };
      }
    } catch (e: any) {
      _response = {
        success: false,
        message: e,
      };
    }
    return _response;
  }

  async _cambiarEstadoSubproceso(
    _codigo: number,
    _estado: number,
    _token: string
  ): Promise<APIResponse> {
    let _response: APIResponse = new APIResponse({});
    try {
      let _result: any = await axios.put(
        `${Global.URL_API}cambiarEstadoSubproceso`,
        {
          codigo: _codigo,
          estado: _estado,
          token: _token,
        }
      );
      if (_result.status === 200) {
        _response = {
          success: _result.data.success,
          message: _result.data.message,
          codigo: _result.data.codigo,
        };
      } else {
        _response = {
          success: false,
          message: "Ocurrió un error de conexión, inténtalo nuevamente",
          codigo: _result.data.codigo,
        };
      }
    } catch (e: any) {
      _response = {
        success: false,
        message: e,
      };
    }
    return _response;
  }

  async _registrarSubproceso(
    _subproceso: string,
    _proceso: number,
    _token: string
  ): Promise<APIResponse> {
    let _response: APIResponse = new APIResponse({});
    try {
      let _result: any = await axios.post(
        `${Global.URL_API}registrarSubproceso`,
        {
          subproceso: _subproceso,
          proceso: _proceso,
          token: _token,
        }
      );
      if (_result.status === 200) {
        _response = {
          success: _result.data.success,
          message: _result.data.message,
          codigo: _result.data.codigo,
        };
      } else {
        _response = {
          success: false,
          message: "Ocurrió un error de conexión, inténtalo nuevamente",
          codigo: _result.data.codigo,
        };
      }
    } catch (e: any) {
      _response = {
        success: false,
        message: e,
      };
    }
    return _response;
  }

  async _editarSubproceso(
    _codigo: number,
    _subproceso: string,
    _proceso: number,
    _token: string
  ): Promise<APIResponse> {
    let _response: APIResponse = new APIResponse({});
    try {
      let _result: any = await axios.put(`${Global.URL_API}editarSubproceso`, {
        proceso: _proceso,
        codigo: _codigo,
        subproceso: _subproceso,
        token: _token,
      });
      if (_result.status === 200) {
        _response = {
          success: _result.data.success,
          message: _result.data.message,
          codigo: _result.data.codigo,
        };
      } else {
        _response = {
          success: false,
          message: "Ocurrió un error de conexión, inténtalo nuevamente",
          codigo: _result.data.codigo,
        };
      }
    } catch (e: any) {
      _response = {
        success: false,
        message: e,
      };
    }
    return _response;
  }
}
