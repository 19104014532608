export interface EmpresaDataI {
  codigo: number;
  ruc: string;
  empresa: string;
  estado: number;
}

export class Empresa implements EmpresaDataI {
  codigo: number;
  ruc: string;
  empresa: string;
  estado: number;

  constructor(data: EmpresaDataI) {
    this.codigo = data.codigo;
    this.ruc = data.ruc;
    this.empresa = data.empresa;
    this.estado = data.estado;
  }
}
