import { _getSession } from "./dashboard.page";
import { APIDocumento } from "../../api/api_documento";
import { APIEmpresa } from "../../api/api_empresa";
import { APIResponse } from "../../model/apiresponse";
import { APISubproceso } from "../../api/api_subproceso";
import { APITipo } from "../../api/api_tipo";
import { Archivo } from "../../model/archivo";
import { Breadcrumb } from "antd";
import { Button } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import { CloseOutlined } from "@ant-design/icons";
import { Col } from "antd";
import { DatePicker } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { Documento } from "../../model/documento";
import { EditOutlined } from "@ant-design/icons";
import { Empresa } from "../../model/empresa";
import { FileExcelFilled } from "@ant-design/icons";
import { FileImageFilled } from "@ant-design/icons";
import { FilePdfFilled } from "@ant-design/icons";
import { FileWordFilled } from "@ant-design/icons";
import { FilterOutlined } from "@ant-design/icons";
import { Fragment } from "react";
import { Input } from "antd";
import { Modal } from "antd";
import { notification } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ReloadOutlined } from "@ant-design/icons";
import { Row } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Select } from "antd";
import { Space } from "antd";
import { Subproceso } from "../../model/subproceso";
import { SyncOutlined } from "@ant-design/icons";
import { Tipo } from "../../model/tipo";
import { Typography } from "antd";
import { useCallback } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import { useState } from "react";
import DocumentoContext from "../../provider/documento-provider";
import DocumentosEditarWidget from "../widgets/documentos.editar.widget";
import DocumentosListarUsuariosWidget from "../widgets/documentos.listar.usuarios.widget";
import DocumentosListarWidget from "../widgets/documentos.listar.widget";
import DocumentosRegistrarWidget from "../widgets/documentos.registrar.widget";
import EmpresaContext from "../../provider/empresa-provider";
import ErrorComponent from "../components/error.component";
import Global from "../../global/global";
import LoadComponent from "../components/load.component";
import locale from "antd/es/date-picker/locale/es_ES";
import SubprocesoContext from "../../provider/subproceso-provider";
import TipoContext from "../../provider/tipo-provider";

function DocumentosPage() {
  const [_documento, _setDocumento] = useState<string>("");
  const [_documentos, _setDocumentos] = useState<Documento[]>([]);
  const [_documentoSeleccionado, _setDocumentoSeleccionado] =
    useState<Documento>();
  const [_documentosTemp, _setDocumentosTemp] = useState<Documento[]>([]);
  const [_listaEmpresas, _setListaEmpresas] = useState<Empresa[]>([]);
  const [_listaSubprocesos, _setListaSubprocesos] = useState<Subproceso[]>([]);
  const [_listaTipos, _setListaTipos] = useState<Tipo[]>([]);
  const [_loading, _setLoading] = useState<boolean>(true);
  const [_modal, _contextHolder] = Modal.useModal();
  const [_mounted, _setMounted] = useState<any>(null);
  const [_visibleEditar, _setVisibleEditar] = useState<boolean>(false);
  const [_visibleNuevo, _setVisibleNuevo] = useState<boolean>(false);
  const [_visibleUsuarios, _setVisibleUsuarios] = useState<boolean>(false);
  const _apiDocumento = useContext<APIDocumento>(DocumentoContext);
  const _apiEmpresa = useContext<APIEmpresa>(EmpresaContext);
  const _apiSubproceso = useContext<APISubproceso>(SubprocesoContext);
  const _apiTipo = useContext<APITipo>(TipoContext);
  const { _token } = _getSession();
  const { Option } = Select;
  const { Title } = Typography;
  let _navigate = useNavigate();

  const _listarSubprocesos = useCallback(async () => {
    _setListaSubprocesos([]);
    let _result: APIResponse = await _apiSubproceso._listarSubprocesos(
      _token,
      1
    );
    if (_result.codigo === 401) {
      _modal.error({
        title: Global.NOMBRE_PROYECTO,
        content: _result.message,
        centered: true,
        onOk: () => {
          localStorage.clear();
          _navigate("/login");
        },
      });
    } else if (_result.success) {
      _setListaSubprocesos(_result.data);
    } else {
      _setListaSubprocesos([]);
    }
  }, [_apiSubproceso, _modal, _navigate, _token]);

  const _listarTipos = useCallback(async () => {
    _setListaSubprocesos([]);
    let _result: APIResponse = await _apiTipo._listarTipos(_token);
    if (_result.codigo === 401) {
      _modal.error({
        title: Global.NOMBRE_PROYECTO,
        content: _result.message,
        centered: true,
        onOk: () => {
          localStorage.clear();
          _navigate("/login");
        },
      });
    } else if (_result.success) {
      _setListaTipos(_result.data);
    } else {
      _setListaTipos([]);
    }
  }, [_apiTipo, _modal, _navigate, _token]);

  const _listarEmpresas = useCallback(async () => {
    _setListaSubprocesos([]);
    let _result: APIResponse = await _apiEmpresa._listarEmpresas(_token);
    if (_result.codigo === 401) {
      _modal.error({
        title: Global.NOMBRE_PROYECTO,
        content: _result.message,
        centered: true,
        onOk: () => {
          localStorage.clear();
          _navigate("/login");
        },
      });
    } else if (_result.success) {
      _setListaEmpresas(_result.data);
    } else {
      _setListaEmpresas([]);
    }
  }, [_apiEmpresa, _modal, _navigate, _token]);

  const _listarDocumentos = useCallback(async () => {
    _setLoading(true);
    _setDocumentos([]);
    _setDocumentosTemp([]);
    let _result: APIResponse = await _apiDocumento._listarDocumentos(
      _token,
      "TODOS"
    );
    if (_result.codigo === 401) {
      _modal.error({
        title: Global.NOMBRE_PROYECTO,
        content: _result.message,
        centered: true,
        onOk: () => {
          localStorage.clear();
          _navigate("/login");
        },
      });
    } else if (_result.success) {
      _setDocumentos(_result.data);
      _setDocumentosTemp(_result.data);
    } else {
      _modal.error({
        title: Global.NOMBRE_PROYECTO,
        content: _result.message,
        centered: true,
      });
      _setDocumentos([]);
      _setDocumentosTemp([]);
      _setLoading(true);
    }
    _setLoading(false);
  }, [_apiDocumento, _modal, _navigate, _token]);

  const _filtrarEstado = () => ({
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
        <Select
          placeholder="Selecciona un estado"
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(_input: any, _option: any) =>
            _option.children.toLowerCase().indexOf(_input.toLowerCase()) >= 0
          }
          filterSort={(_optionA: any, _optionB: any) =>
            _optionA.children
              .toLowerCase()
              .localeCompare(_optionB.children.toLowerCase())
          }
          style={{ width: "250px" }}
          onChange={(_value: string, _option: any) => {
            if (_value) {
              let _documentosFound: Documento[] = [];
              for (let i = 0; i < _documentosTemp.length; i++) {
                if (_documentosTemp[i].estado === _value) {
                  _documentosFound.push(_documentosTemp[i]);
                }
              }
              _setDocumentos(_documentosFound);
            } else {
              _setDocumentos(_documentosTemp);
            }
          }}
        >
          <Option key="estado_vigente" value="VIGENTE">
            VIGENTE
          </Option>
          <Option key="estado_obsoleto" value="OBSOLETO">
            OBSOLETO
          </Option>
        </Select>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <FilterOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  const _filtrarTipo = () => ({
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
        <Select
          placeholder="Selecciona o busca un tipo"
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(_input: any, _option: any) =>
            _option.children.toLowerCase().indexOf(_input.toLowerCase()) >= 0
          }
          filterSort={(_optionA: any, _optionB: any) =>
            _optionA.children
              .toLowerCase()
              .localeCompare(_optionB.children.toLowerCase())
          }
          style={{ width: "200px" }}
          onChange={(_value: any, _option: any) => {
            if (_value) {
              let _documentosFound: Documento[] = [];
              for (let i = 0; i < _documentosTemp.length; i++) {
                if (
                  _documentosTemp[i].tipo.tipo
                    .toString()
                    .toUpperCase()
                    .indexOf(_option.children.toUpperCase()) > -1
                ) {
                  _documentosFound.push(_documentosTemp[i]);
                }
              }
              _setDocumentos(_documentosFound);
            } else {
              _setDocumentos(_documentosTemp);
            }
          }}
        >
          {_listaTipos.map((_tipo: Tipo, _index: number) => (
            <Option key={`${_tipo.codigo}_${_index}`} value={_tipo.codigo}>
              {_tipo.tipo}
            </Option>
          ))}
        </Select>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <FilterOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  const _buscarDocumentoPorCodigo = () => ({
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
        <Input
          value={_documento}
          onChange={(e) => _setDocumento(e.target.value)}
          onPressEnter={() => _buscarPorCodigo(_documento)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            onClick={() => _buscarPorCodigo(_documento)}
            icon={<SearchOutlined />}
            size="small"
            className="button-info"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => _limpiar()}
            icon={<CloseOutlined />}
            size="small"
            danger
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  const _buscarDocumentoPorNombre = () => ({
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
        <Input
          value={_documento}
          onChange={(e) => _setDocumento(e.target.value)}
          onPressEnter={() => _buscarPorNombre(_documento)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            onClick={() => _buscarPorNombre(_documento)}
            icon={<SearchOutlined />}
            size="small"
            className="button-info"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => _limpiar()}
            icon={<CloseOutlined />}
            size="small"
            danger
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  const _buscarPorCodigo = (_documento: string) => {
    if (_documento.length > 0) {
      let _documentosFound: Documento[] = [];
      for (let i = 0; i < _documentosTemp.length; i++) {
        if (
          _documentosTemp[i].documento
            .toUpperCase()
            .indexOf(_documento.toUpperCase()) > -1
        ) {
          _documentosFound.push(_documentosTemp[i]);
        }
      }
      _setDocumentos(_documentosFound);
    }
  };

  const _buscarPorNombre = (_documento: string) => {
    if (_documento.length > 0) {
      let _documentosFound: Documento[] = [];
      for (let i = 0; i < _documentosTemp.length; i++) {
        if (
          _documentosTemp[i].nombre
            .toUpperCase()
            .indexOf(_documento.toUpperCase()) > -1
        ) {
          _documentosFound.push(_documentosTemp[i]);
        }
      }
      _setDocumentos(_documentosFound);
    }
  };

  const _buscarDocumentoPorFecha = (_tipo: string) => ({
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
        <label>&nbsp;Fecha:</label>
        <DatePicker
          style={{ width: "100%", margin: "4px 1%" }}
          allowClear
          locale={locale}
          onChange={(_moment: any, _date: any) => {
            _buscarPorFecha(_moment, _date, _tipo);
          }}
          format={"YYYY-MM-DD"}
        />
      </div>
    ),
    filterIcon: (filtered: any) => (
      <CalendarOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  const _buscarPorFecha = (_moment: any, _date: any, _tipo: string) => {
    _setLoading(true);
    if (!_date) {
      _limpiar();
    } else {
      let _documentosFound: Documento[] = [];
      _documentosTemp.forEach((_documento: Documento) => {
        let _fecha: string =
          _tipo === "creacion"
            ? _documento.fecha?.substring(0, 10)!
            : _documento.vigencia?.substring(0, 10)!;
        if (_fecha.indexOf(_date) > -1) {
          _documentosFound.push(_documento);
        }
      });
      _setDocumentos(_documentosFound);
    }
    _setLoading(false);
  };

  const _limpiar = () => {
    _setDocumento("");
    _setDocumentos(_documentosTemp);
  };

  const _renderEstado = (_value: string, _row: Documento, _index: number) => {
    return (
      <Space>
        <div
          key={`estado${_row.codigo}`}
          className={
            _value === "VIGENTE" ? "div-status-active" : "div-status-inactive"
          }
        />
      </Space>
    );
  };

  const _renderArchivos = (_value: string, _row: Documento, _index: number) => {
    return (
      <Space key={`archivos_lista_${_row.codigo}`}>
        {_row.archivos.map((_archivo: Archivo, _index: number) => {
          return (
            <a
              key={`enlace_a_${_archivo.codigo}`}
              href={`${Global.URL_DOCUMENTOS}${_archivo.url}`}
              target="_blank"
              rel="noreferrer"
            >
              {_getIconFile(_archivo.tipo)}
            </a>
          );
        })}
      </Space>
    );
  };

  const _getIconFile = (_tipo: string) => {
    if (_tipo.toUpperCase() === "PDF") {
      return <FilePdfFilled className="icon-pdf" />;
    }

    if (_tipo.toUpperCase() === "XLSX" || _tipo.toUpperCase() === "XLS") {
      return <FileExcelFilled className="icon-excel" />;
    }

    if (_tipo.toUpperCase() === "DOCX" || _tipo.toUpperCase() === "DOC") {
      return <FileWordFilled className="icon-word" />;
    }

    if (
      _tipo.toUpperCase() === "PNG" ||
      _tipo.toUpperCase() === "JPG" ||
      _tipo.toUpperCase() === "JPEG"
    ) {
      return <FileImageFilled className="icon-imagen" />;
    }
  };

  const _renderAcciones = (_value: string, _row: Documento, _index: number) => {
    return _value === "VIGENTE" ? (
      <Space>
        <EditOutlined
          className="icon-edit"
          onClick={() => {
            _setDocumentoSeleccionado(_row);
            _abrirEditar();
          }}
        />
        <DeleteOutlined
          className="icon-delete"
          onClick={() => {
            _cambiarEstadoDocumento(_row.codigo, "OBSOLETO");
          }}
        />
        {_row.usuarios.length > 0 && (
          <UserOutlined
            className="icon-user"
            onClick={() => {
              _setDocumentoSeleccionado(_row);
              _abrirUsuarios();
            }}
          />
        )}
      </Space>
    ) : (
      <Space>
        <SyncOutlined
          className="icon-edit"
          onClick={() => {
            _cambiarEstadoDocumento(_row.codigo, "VIGENTE");
          }}
        />
        {_row.usuarios.length > 0 && (
          <UserOutlined
            className="icon-user"
            onClick={() => {
              _setDocumentoSeleccionado(_row);
              _abrirUsuarios();
            }}
          />
        )}
      </Space>
    );
  };

  const _cambiarEstadoDocumento = useCallback(
    async (_codigo: number, _estado: string) => {
      let _result: APIResponse = await _apiDocumento._cambiarEstadoDocumento(
        _codigo,
        _estado,
        _token
      );
      if (_result.success) {
        notification.success({
          message: Global.NOMBRE_PROYECTO,
          description: _result.message,
          duration: 2,
        });
      } else {
        notification.error({
          message: Global.NOMBRE_PROYECTO,
          description: _result.message,
          duration: 2,
        });
      }
      _listarDocumentos();
    },
    [_apiDocumento, _listarDocumentos, _token]
  );

  const _abrirNuevo = () => {
    _setVisibleNuevo(true);
  };

  const _cerrarNuevo = () => {
    _setVisibleNuevo(false);
  };

  const _abrirEditar = () => {
    _setVisibleEditar(true);
  };
  const _cerrarEditar = () => {
    _setVisibleEditar(false);
  };

  const _abrirUsuarios = () => {
    _setVisibleUsuarios(true);
  };
  const _cerrarUsuarios = () => {
    _setVisibleUsuarios(false);
  };

  useEffect(() => {
    _setMounted(true);
    _listarSubprocesos();
    _listarDocumentos();
    _listarTipos();
    _listarEmpresas();
    return () => {
      _setMounted(false);
    };
  }, [_listarTipos, _listarDocumentos, _listarSubprocesos, _listarEmpresas]);

  if (_mounted === null) {
    return <LoadComponent />;
  }

  if (!_mounted) {
    return <ErrorComponent />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Breadcrumb>
            <Breadcrumb.Item>Inicio</Breadcrumb.Item>
            <Breadcrumb.Item>
              <a href="/documentos">Documentos</a>
            </Breadcrumb.Item>
          </Breadcrumb>
          <hr />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Title level={3}>Documentos</Title>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className="mb-10">
          <Space>
            <Button
              icon={<PlusOutlined />}
              className="button-success"
              onClick={_abrirNuevo}
            >
              Agregar
            </Button>
            <Button
              icon={<ReloadOutlined />}
              className="button-info"
              onClick={() => {
                _listarDocumentos();
              }}
            >
              Recargar
            </Button>
          </Space>
        </Col>
        {/* Lista de áreas */}
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <DocumentosListarWidget
            _documentos={_documentos}
            _buscarDocumentoPorCodigo={_buscarDocumentoPorCodigo}
            _buscarDocumentoPorNombre={_buscarDocumentoPorNombre}
            _buscarDocumentoPorFecha={_buscarDocumentoPorFecha}
            _loading={_loading}
            _renderAcciones={_renderAcciones}
            _renderEstado={_renderEstado}
            _renderArchivos={_renderArchivos}
            _filtrarTipo={_filtrarTipo}
            _filtrarEstado={_filtrarEstado}
          />
        </Col>
      </Row>
      {/* Nuevo registro */}
      <Modal
        title={<b>Nuevo registro</b>}
        visible={_visibleNuevo}
        centered={true}
        closable={false}
        footer={[]}
      >
        <DocumentosRegistrarWidget
          _apiDocumento={_apiDocumento}
          _cerrarNuevo={_cerrarNuevo}
          _listarDocumentos={_listarDocumentos}
          _listaSubprocesos={_listaSubprocesos}
          _listaTipos={_listaTipos}
          _listaEmpresas={_listaEmpresas}
          _token={_token}
        />
      </Modal>
      {/* Editar registro */}
      <Modal
        title={<b>Editar registro</b>}
        visible={_visibleEditar}
        centered={true}
        // closable={false}
        footer={[]}
        onCancel={_cerrarEditar}
      >
        <DocumentosEditarWidget
          _apiDocumento={_apiDocumento}
          _documentoSeleccionado={_documentoSeleccionado!}
          _cerrarEditar={_cerrarEditar}
          _listarDocumentos={_listarDocumentos}
          _listaSubprocesos={_listaSubprocesos}
          _listaTipos={_listaTipos}
          _listaEmpresas={_listaEmpresas}
          _token={_token}
        />
      </Modal>
      {/* Modal de lista de usuarios */}
      <Modal
        title={`Documento: ${_documentoSeleccionado?.documento}`}
        visible={_visibleUsuarios}
        centered={true}
        footer={[]}
        onCancel={_cerrarUsuarios}
        width={1200}
      >
        <DocumentosListarUsuariosWidget
          _documentoSeleccionado={_documentoSeleccionado!}
          // _filtrarSede={_fil}
        />
      </Modal>
      {_contextHolder}
    </Fragment>
  );
}

export default DocumentosPage;
