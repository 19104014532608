import { Col } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { EditOutlined } from "@ant-design/icons";
import { Fragment } from "react";
import { Documento } from "../../model/documento";
import { Row } from "antd";
import { Space } from "antd";
import { Subproceso } from "../../model/subproceso";
import { SyncOutlined } from "@ant-design/icons";
import { UserOutlined } from "@ant-design/icons";
import { Table } from "antd";
import { Tipo } from "../../model/tipo";
import { isMobile } from "react-device-detect";
import { Empresa } from "../../model/empresa";
import Moment from "moment";

function DocumentosListarWidget({
  _documentos,
  _buscarDocumentoPorCodigo,
  _buscarDocumentoPorNombre,
  _buscarDocumentoPorFecha,
  _loading,
  _renderAcciones,
  _renderEstado,
  _renderArchivos,
  _filtrarTipo,
  _filtrarEstado,
}: {
  _documentos: Documento[];
  _buscarDocumentoPorCodigo: Function;
  _buscarDocumentoPorNombre: Function;
  _buscarDocumentoPorFecha: Function;
  _loading: boolean;
  _renderAcciones: Function;
  _renderEstado: Function;
  _renderArchivos: Function;
  _filtrarTipo: Function;
  _filtrarEstado: Function;
}) {
  const expandedRowRender = (_documento: Documento) => {
    return (
      <Table
        bordered
        key="documentos-detalle"
        size="small"
        rowKey="codigo"
        columns={[
          {
            title: "Subproceso",
            dataIndex: "subproceso",
            key: "subproceso",
            width: "25%",
            align: "center",
            render: (_value: Subproceso, _row: Documento, _index: number) => {
              return _value.subproceso;
            },
          },
          {
            title: "Proceso",
            dataIndex: "subproceso",
            key: "subproceso",
            width: "25%",
            align: "center",
            render: (_value: Subproceso, _row: Documento, _index: number) => {
              return _value.proceso.proceso;
            },
          },
          {
            title: "Área",
            dataIndex: "subproceso",
            key: "subproceso",
            width: "25%",
            align: "center",
            render: (_value: Subproceso, _row: Documento, _index: number) => {
              return _value.proceso.area.area;
            },
          },
          {
            title: "Empresa",
            dataIndex: "empresa",
            key: "empresa",
            width: "25%",
            align: "center",
            render: (_value: Empresa, _row: Documento, _index: number) => {
              return _value.empresa;
            },
          },
        ]}
        pagination={false}
        loading={_loading}
        dataSource={[_documento]}
      />
    );
  };

  return (
    <Fragment>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className="mb-10">
          <Space direction={isMobile ? "vertical" : "horizontal"}>
            Leyenda (Acciones):&nbsp;&nbsp;
            <EditOutlined className="icon-edit" /> Editar&nbsp;&nbsp;
            <DeleteOutlined className="icon-delete" /> Borrar&nbsp;&nbsp;
            <SyncOutlined className="icon-edit" /> Activar&nbsp;&nbsp;
            <UserOutlined className="icon-user" /> Usuarios&nbsp;&nbsp;
          </Space>
          <br />
          <Space direction={isMobile ? "vertical" : "horizontal"}>
            Leyenda (Estado):&nbsp;&nbsp;
            <div className="div-status-active" /> Vigente&nbsp;&nbsp;
            <div className="div-status-inactive" /> Obsoleto&nbsp;&nbsp;
          </Space>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Table
            bordered
            key="documentos"
            size="small"
            rowKey="codigo"
            columns={[
              {
                title: "Estado",
                dataIndex: "estado",
                key: "estado",
                width: "5%",
                align: "center",
                ..._filtrarEstado(),
                render: _renderEstado,
                sorter: (a: Documento, b: Documento) =>
                  a.estado.localeCompare(b.estado),
              },
              {
                title: "Fecha",
                dataIndex: "fecha",
                key: "fecha",
                width: "15%",
                align: "center",
                ..._buscarDocumentoPorFecha("creacion"),
                sorter: (a: Documento, b: Documento) =>
                  a.fecha.localeCompare(b.fecha),
                render: (_value: string, _row: Documento, _index: number) => {
                  return Moment(_value).format("DD-MM-YYYY H:mm:ss");
                },
              },
              {
                title: "Código",
                dataIndex: "documento",
                key: "documento",
                width: "10%",
                align: "center",
                ..._buscarDocumentoPorCodigo(),
                sorter: (a: Documento, b: Documento) =>
                  a.documento.localeCompare(b.documento),
              },
              {
                title: "Nombre",
                dataIndex: "nombre",
                key: "nombre",
                width: "20%",
                align: "center",
                ..._buscarDocumentoPorNombre(),
                sorter: (a: Documento, b: Documento) =>
                  a.nombre.localeCompare(b.nombre),
              },
              {
                title: "Tipo",
                dataIndex: "tipo",
                key: "tipo",
                width: "10%",
                align: "center",
                ..._filtrarTipo(),
                sorter: (a: Documento, b: Documento) =>
                  a.tipo.tipo.localeCompare(b.tipo.tipo),
                render: (_value: Tipo, _row: Documento, _index: number) => {
                  return _value.tipo;
                },
              },
              {
                title: "Versión",
                dataIndex: "version",
                key: "version",
                width: "5%",
                align: "center",
                sorter: (a: Documento, b: Documento) =>
                  parseFloat(a.version) - parseFloat(b.version),
              },
              {
                title: "Vigencia",
                dataIndex: "vigencia",
                key: "vigencia",
                width: "10%",
                align: "center",
                ..._buscarDocumentoPorFecha("vigencia"),
                sorter: (a: Documento, b: Documento) =>
                  a.vigencia.localeCompare(b.vigencia),
                render: (_value: Date, _row: Documento, _index: number) => {
                  return Moment(_value).format("DD-MM-YYYY");
                },
              },
              {
                title: "Archivos",
                dataIndex: "archivos",
                key: "archivos",
                width: "15%",
                align: "center",
                render: _renderArchivos,
              },
              {
                title: "Acciones",
                dataIndex: "estado",
                key: "estado",
                width: "10%",
                align: "center",
                render: _renderAcciones,
              },
            ]}
            expandable={{ expandedRowRender }}
            pagination={{
              pageSize: 20,
              simple: false,
            }}
            loading={_loading}
            dataSource={_documentos.map((_documento: Documento) => ({
              ..._documento,
              ...expandedRowRender(_documento),
            }))}
          />
        </Col>
      </Row>
    </Fragment>
  );
}

export default DocumentosListarWidget;
