import { _getSession } from "./dashboard.page";
import { APIDocumento } from "../../api/api_documento";
import { APIEmpresa } from "../../api/api_empresa";
import { APIPermiso } from "../../api/api_permiso";
import { APIPuesto } from "../../api/api_puesto";
import { APIResponse } from "../../model/apiresponse";
import { APISede } from "../../api/api_sede";
import { APIUsuario } from "../../api/api_usuario";
import { Breadcrumb } from "antd";
import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { Col } from "antd";
import { Documento } from "../../model/documento";
import { Empresa } from "../../model/empresa";
import { FilterOutlined } from "@ant-design/icons";
import { Fragment } from "react";
import { Input } from "antd";
import { Key } from "react";
import { Modal } from "antd";
import { Puesto } from "../../model/puesto";
import { Row } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { SearchOutlined } from "@ant-design/icons";
import { Sede } from "../../model/sede";
import { Select } from "antd";
import { Space } from "antd";
import { Table } from "antd";
import { Typography } from "antd";
import { useCallback } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Usuario } from "../../model/usuario";
import { UsuarioDescarga } from "../../model/usuario-descarga";
import DocumentoContext from "../../provider/documento-provider";
import EmpresaContext from "../../provider/empresa-provider";
import ErrorComponent from "../components/error.component";
import Global from "../../global/global";
import LoadComponent from "../components/load.component";
import PermisoContext from "../../provider/permiso-provider";
import PuestoContext from "../../provider/puesto-provider";
import SedeContext from "../../provider/sede-provider";
import UsuarioContext from "../../provider/usuario-provider";

function PermisosPage() {
  const [_documentos, _setDocumentos] = useState<Documento[]>([]);
  const [_documentoSeleccionado, _setDocumentoSeleccionado] =
    useState<Documento>();
  const [_empresas, _setEmpresas] = useState<Empresa[]>([]);
  const [_loading, _setLoading] = useState<boolean>(true);
  const [_modal, _contextHolder] = Modal.useModal();
  const [_mounted, _setMounted] = useState<any>(null);
  const [_puestos, _setPuestos] = useState<Puesto[]>([]);
  const [_sedes, _setSedes] = useState<Sede[]>([]);
  const [_selectedRowKeys, _setSelectedRowKeys] = useState<Key[]>([]);
  const [_usuario, _setUsuario] = useState<string>("");
  const [_usuarios, _setUsuarios] = useState<Usuario[]>([]);
  const [_usuariosTemp, _setUsuariosTemp] = useState<Usuario[]>([]);
  const _apiDocumento = useContext<APIDocumento>(DocumentoContext);
  const _apiEmpresa = useContext<APIEmpresa>(EmpresaContext);
  const _apiPermiso = useContext<APIPermiso>(PermisoContext);
  const _apiPuesto = useContext<APIPuesto>(PuestoContext);
  const _apiSede = useContext<APISede>(SedeContext);
  const _apiUsuario = useContext<APIUsuario>(UsuarioContext);
  const { _token } = _getSession();
  const { Option } = Select;
  const { Title } = Typography;
  let _navigate = useNavigate();

  const _listarEmpresas = useCallback(async () => {
    _setEmpresas([]);
    let _result: APIResponse = await _apiEmpresa._listarEmpresas(_token);
    if (_result.codigo === 401) {
      _modal.error({
        title: Global.NOMBRE_PROYECTO,
        content: _result.message,
        centered: true,
        onOk: () => {
          localStorage.clear();
          _navigate("/login");
        },
      });
    } else if (_result.success) {
      _setEmpresas(_result.data);
    } else {
      _modal.error({
        title: Global.NOMBRE_PROYECTO,
        content: _result.message,
        centered: true,
      });
      _setEmpresas([]);
    }
  }, [_apiEmpresa, _modal, _navigate, _token]);

  const _listarSedes = useCallback(async () => {
    _setSedes([]);
    let _result: APIResponse = await _apiSede._listarSedes(_token);
    if (_result.codigo === 401) {
      _modal.error({
        title: Global.NOMBRE_PROYECTO,
        content: _result.message,
        centered: true,
        onOk: () => {
          localStorage.clear();
          _navigate("/login");
        },
      });
    } else if (_result.success) {
      _setSedes(_result.data);
    } else {
      _modal.error({
        title: Global.NOMBRE_PROYECTO,
        content: _result.message,
        centered: true,
      });
      _setSedes([]);
    }
  }, [_apiSede, _modal, _navigate, _token]);

  const _listarPuestos = useCallback(async () => {
    _setPuestos([]);
    let _result: APIResponse = await _apiPuesto._listarPuestos(_token);
    if (_result.codigo === 401) {
      _modal.error({
        title: Global.NOMBRE_PROYECTO,
        content: _result.message,
        centered: true,
        onOk: () => {
          localStorage.clear();
          _navigate("/login");
        },
      });
    } else if (_result.success) {
      _setPuestos(_result.data);
    } else {
      _modal.error({
        title: Global.NOMBRE_PROYECTO,
        content: _result.message,
        centered: true,
      });
      _setPuestos([]);
    }
  }, [_apiPuesto, _modal, _navigate, _token]);

  const _listarDocumentos = useCallback(async () => {
    _setDocumentos([]);
    let _result: APIResponse = await _apiDocumento._listarDocumentos(
      _token,
      "VIGENTE"
    );
    if (_result.codigo === 401) {
      _modal.error({
        title: Global.NOMBRE_PROYECTO,
        content: _result.message,
        centered: true,
        onOk: () => {
          localStorage.clear();
          _navigate("/login");
        },
      });
    } else if (_result.success) {
      _setDocumentos(_result.data);
    } else {
      _modal.error({
        title: Global.NOMBRE_PROYECTO,
        content: _result.message,
        centered: true,
      });
      _setDocumentos([]);
    }
  }, [_apiDocumento, _modal, _navigate, _token]);

  const _listarUsuarios = useCallback(async () => {
    _setLoading(true);
    _setUsuarios([]);
    _setUsuariosTemp([]);
    let _result: APIResponse = await _apiUsuario._listarUsuarios(_token);
    if (_result.codigo === 401) {
      _modal.error({
        title: Global.NOMBRE_PROYECTO,
        content: _result.message,
        centered: true,
        onOk: () => {
          localStorage.clear();
          _navigate("/login");
        },
      });
    } else if (_result.success) {
      _setUsuarios(_result.data);
      _setUsuariosTemp(_result.data);
    } else {
      _modal.error({
        title: Global.NOMBRE_PROYECTO,
        content: _result.message,
        centered: true,
      });
      _setUsuarios([]);
      _setUsuariosTemp([]);
    }
    _setLoading(false);
  }, [_apiUsuario, _modal, _navigate, _token]);

  const _marcarUsuariosActuales = (_usuarios: Usuario[]) => {
    let _selectedRowKeys: React.Key[] = [];
    let _selectedRows: Usuario[] = [];
    _usuarios.forEach((_value: Usuario, _index: number) => {
      _selectedRowKeys.push(_value.codigo);
      _selectedRows.push(_value);
    });
    _onChangeSelectTable(_selectedRowKeys);
  };

  const _onChangeDocumento = (_value: any, _option: any) => {
    _setLoading(true);
    let _documento: Documento = _documentos.filter(
      (_documento: Documento, _index: number) => {
        return _documento.codigo === _value;
      }
    )[0];
    if (_documento) {
      _setDocumentoSeleccionado(_documento);
      let _usuarios: Usuario[] = [];
      _documento.usuarios.forEach((_value: UsuarioDescarga, _index: number) => {
        _usuarios.push(_value.usuario);
      });
      _marcarUsuariosActuales(_usuarios);
    } else {
      _setDocumentoSeleccionado(undefined);
      _marcarUsuariosActuales([]);
    }
    _setLoading(false);
  };

  const _onChangeSelectTable = (_selectedRowKeys: React.Key[]) => {
    _setSelectedRowKeys(_selectedRowKeys);
  };

  const _registrarPermiso = async (
    _token: string,
    _usuarios: Key[],
    _documento: Documento
  ) => {
    _modal.confirm({
      title: Global.NOMBRE_PROYECTO,
      content: "¿Está seguro de actualizar los permisos?",
      centered: true,
      okText: "Si, actualizar",
      cancelText: "No, cancelar",
      onOk: async () => {
        let _result: APIResponse = await _apiPermiso._registrarPermiso(
          _token,
          _documento,
          _usuarios
        );
        if (_result.codigo === 401) {
          _modal.error({
            title: Global.NOMBRE_PROYECTO,
            content: _result.message,
            centered: true,
            onOk: () => {
              localStorage.clear();
              _navigate("/login");
            },
          });
        } else if (_result.success) {
          _modal.success({
            title: Global.NOMBRE_PROYECTO,
            content: _result.message,
            centered: true,
          });
        } else {
          _modal.error({
            title: Global.NOMBRE_PROYECTO,
            content: _result.message,
            centered: true,
          });
        }
        _listarDocumentos();
        _listarUsuarios();
      },
    });
  };

  const _filtrarSede = (_sedes: Sede[]) => ({
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
        <Select
          placeholder="Selecciona o busca una sede"
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(_input: any, _option: any) =>
            _option.children.toLowerCase().indexOf(_input.toLowerCase()) >= 0
          }
          filterSort={(_optionA: any, _optionB: any) =>
            _optionA.children
              .toLowerCase()
              .localeCompare(_optionB.children.toLowerCase())
          }
          style={{ width: "200px" }}
          onChange={(_value: any, _option: any) => {
            if (_value) {
              let _usuariosFound: Usuario[] = [];
              for (let i = 0; i < _usuarios.length; i++) {
                if (
                  _usuarios[i].sede.sede
                    .toString()
                    .toUpperCase()
                    .indexOf(_option.children.toUpperCase()) > -1
                ) {
                  _usuariosFound.push(_usuarios[i]);
                }
              }
              _setUsuarios(_usuariosFound);
            } else {
              _setUsuarios(_usuariosTemp);
            }
          }}
        >
          {_sedes.map((_sede: Sede, _index: number) => (
            <Option key={`${_sede.codigo}_${_index}`} value={_sede.codigo}>
              {_sede.sede}
            </Option>
          ))}
        </Select>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <FilterOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  const _filtrarPuesto = (_puestos: Puesto[]) => ({
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
        <Select
          placeholder="Selecciona o busca un puesto"
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(_input: any, _option: any) =>
            _option.children.toLowerCase().indexOf(_input.toLowerCase()) >= 0
          }
          filterSort={(_optionA: any, _optionB: any) =>
            _optionA.children
              .toLowerCase()
              .localeCompare(_optionB.children.toLowerCase())
          }
          style={{ width: "200px" }}
          onChange={(_value: any, _option: any) => {
            if (_value) {
              let _usuariosFound: Usuario[] = [];
              for (let i = 0; i < _usuarios.length; i++) {
                if (
                  _usuarios[i].puesto.puesto
                    .toString()
                    .toUpperCase()
                    .indexOf(_option.children.toUpperCase()) > -1
                ) {
                  _usuariosFound.push(_usuarios[i]);
                }
              }
              _setUsuarios(_usuariosFound);
            } else {
              _setUsuarios(_usuariosTemp);
            }
          }}
        >
          {_puestos.map((_puesto: Puesto, _index: number) => (
            <Option key={`${_puesto.codigo}_${_index}`} value={_puesto.codigo}>
              {_puesto.puesto}
            </Option>
          ))}
        </Select>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <FilterOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  const _filtrarEmpresa = (_empresas: Empresa[]) => ({
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
        <Select
          placeholder="Selecciona o busca una empresa"
          allowClear
          style={{ width: "200px" }}
          onChange={(_value: any, _option: any) => {
            if (_value) {
              let _usuariosFound: Usuario[] = [];
              for (let i = 0; i < _usuarios.length; i++) {
                if (
                  _usuarios[i].empresa.empresa.toString().toUpperCase() ===
                  _option.children.toUpperCase()
                ) {
                  _usuariosFound.push(_usuarios[i]);
                }
              }
              _setUsuarios(_usuariosFound);
            } else {
              _setUsuarios(_usuariosTemp);
            }
          }}
        >
          {_empresas.map((_empresa: Empresa, _index: number) => (
            <Option
              key={`${_empresa.codigo}_${_index}`}
              value={_empresa.codigo}
            >
              {_empresa.empresa}
            </Option>
          ))}
        </Select>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <FilterOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  const _buscarUsuario = () => ({
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
        <Input
          allowClear
          value={_usuario}
          onChange={(e) => _setUsuario(e.target.value)}
          onPressEnter={() => _buscar(_usuario)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            onClick={() => _buscar(_usuario)}
            icon={<SearchOutlined />}
            size="small"
            className="button-info"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => _limpiar()}
            icon={<CloseOutlined />}
            size="small"
            danger
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  const _buscar = (_usuario: string) => {
    if (_usuario.length > 0) {
      let _usuariosFound: Usuario[] = [];
      for (let i = 0; i < _usuariosTemp.length; i++) {
        if (
          _usuariosTemp[i].nombres
            .toUpperCase()
            .indexOf(_usuario.toUpperCase()) > -1
        ) {
          _usuariosFound.push(_usuariosTemp[i]);
        }
      }
      _setUsuarios(_usuariosFound);
    }
  };

  const _limpiar = () => {
    _setUsuario("");
    _setUsuarios(_usuariosTemp);
  };

  useEffect(() => {
    _setMounted(true);
    _listarDocumentos();
    _listarUsuarios();
    _listarEmpresas();
    _listarSedes();
    _listarPuestos();
    return () => {
      _setMounted(false);
    };
  }, [
    _listarDocumentos,
    _listarEmpresas,
    _listarPuestos,
    _listarSedes,
    _listarUsuarios,
  ]);

  if (_mounted === null) {
    return <LoadComponent />;
  }

  if (!_mounted) {
    return <ErrorComponent />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Breadcrumb>
            <Breadcrumb.Item>Inicio</Breadcrumb.Item>
            <Breadcrumb.Item>
              <a href="/permisos">Permisos</a>
            </Breadcrumb.Item>
          </Breadcrumb>
          <hr />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Title level={3}>Permisos</Title>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <label>Documento</label>
          <Select
            onChange={_onChangeDocumento}
            placeholder="Selecciona un documento"
            allowClear
            showSearch
            optionFilterProp="children"
            filterOption={(_input: any, _option: any) =>
              _option.children.toLowerCase().indexOf(_input.toLowerCase()) >= 0
            }
            filterSort={(_optionA: any, _optionB: any) =>
              _optionA.children
                .toLowerCase()
                .localeCompare(_optionB.children.toLowerCase())
            }
            className="mb-10"
            style={{ width: "100%" }}
          >
            {_documentos.map((_documento: Documento, _index: number) => (
              <Option
                key={`${_documento.codigo}_${_index}`}
                value={_documento.codigo}
              >
                {_documento.nombre}
              </Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Table
            key="areas"
            size="small"
            rowKey="codigo"
            rowSelection={{
              selectedRowKeys: _selectedRowKeys,
              onChange: _onChangeSelectTable,
              preserveSelectedRowKeys: true
            }}
            columns={[
              {
                title: "Usuario",
                dataIndex: "nombres",
                key: "nombres",
                width: "30%",
                align: "center",
                ..._buscarUsuario(),
                sorter: (a: Usuario, b: Usuario) =>
                  a.nombres.localeCompare(b.nombres),
              },
              {
                title: "Sede",
                dataIndex: "sede",
                key: "sede",
                width: "20%",
                align: "center",
                ..._filtrarSede(_sedes),
                sorter: (a: Usuario, b: Usuario) =>
                  a.sede.sede.localeCompare(b.sede.sede),
                render: (_value: Sede, _row: Usuario, _index: number) => {
                  return _value.sede;
                },
              },
              {
                title: "Puesto",
                dataIndex: "puesto",
                key: "puesto",
                width: "30%",
                align: "center",
                ..._filtrarPuesto(_puestos),
                sorter: (a: Usuario, b: Usuario) =>
                  a.puesto.puesto.localeCompare(b.puesto.puesto),
                render: (_value: Puesto, _row: Usuario, _index: number) => {
                  return _value.puesto;
                },
              },
              {
                title: "Empresa",
                dataIndex: "empresa",
                key: "empresa",
                width: "20%",
                align: "center",
                ..._filtrarEmpresa(_empresas),
                sorter: (a: Usuario, b: Usuario) =>
                  a.empresa.empresa.localeCompare(b.empresa.empresa),
                render: (_value: Empresa, _row: Usuario, _index: number) => {
                  return _value.empresa;
                },
              },
            ]}
            pagination={{
              pageSize: 20,
              simple: false,
            }}
            loading={_loading}
            dataSource={_usuarios.map((_usuario: Usuario) => ({
              ..._usuario,
            }))}
          />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          xxl={24}
          className="center-text"
        >
          <Button
            icon={<SaveOutlined />}
            className="button-success"
            onClick={() => {
              _registrarPermiso(
                _token,
                _selectedRowKeys,
                _documentoSeleccionado!
              );
            }}
            disabled={!_documentoSeleccionado && true}
          >
            Registrar
          </Button>
        </Col>
      </Row>
      {_contextHolder}
    </Fragment>
  );
}

export default PermisosPage;
