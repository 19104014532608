import axios from "axios";
import Global from "../global/global";
import { APIResponse } from "../model/apiresponse";
import { Usuario, UsuarioDataI } from "../model/usuario";

export class APIUsuario {
  async _login(_correo: string, _password: string): Promise<APIResponse> {
    let _response: APIResponse = new APIResponse({});
    try {
      let _result: any = await axios.post(`${Global.URL_API}login`, {
        correo: _correo,
        password: _password,
      });
      if (_result.status === 200) {
        if (_result.data.success) {
          let _usuario: Usuario = new Usuario(_result.data.data);
          if (_usuario.perfil.perfil === "USUARIO") {
            _response = {
              success: false,
              message: "No estás autorizado para ingresar",
            };
          } else {
            localStorage.setItem(
              Global.TOKEN,
              JSON.stringify(_result.data.token)
            );
            localStorage.setItem(
              Global.USUARIO_SESSION,
              JSON.stringify(_usuario)
            );
            _response = {
              success: _result.data.success,
              message: _result.data.message,
              data: _usuario,
            };
          }
        } else {
          _response = {
            success: _result.data.success,
            message: _result.data.message,
          };
        }
      } else {
        _response = {
          success: false,
          message: "Ocurrió un error de conexión, inténtalo nuevamente",
        };
      }
    } catch (e: any) {
      _response = {
        success: false,
        message: e,
      };
    }
    return _response;
  }

  async _listarUsuarios(_token: string): Promise<APIResponse> {
    let _response: APIResponse = new APIResponse({});
    try {
      let _result: any = await axios.post(`${Global.URL_API}listarUsuarios`, {
        token: _token,
      });
      if (_result.status === 200) {
        if (_result.data.success) {
          let _usuarios: Usuario[] = [];
          _result.data.data.forEach((_usuario: UsuarioDataI) => {
            _usuarios.push(new Usuario(_usuario));
          });
          _response = {
            success: _result.data.success,
            data: _usuarios,
            codigo: _result.data.codigo,
          };
        } else {
          _response = {
            success: _result.data.success,
            message: _result.data.message,
            codigo: _result.data.codigo,
          };
        }
      } else {
        _response = {
          success: false,
          message: "Ocurrió un error de conexión, inténtalo nuevamente",
          codigo: _result.data.codigo,
        };
      }
    } catch (e: any) {
      _response = {
        success: false,
        message: e,
      };
    }
    return _response;
  }
}
