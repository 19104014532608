import "../../styles/pages/login.page.scss";
import { APIResponse } from "../../model/apiresponse";
import { APIUsuario } from "../../api/api_usuario";
import { Button } from "antd";
import { Col } from "antd";
import { Form } from "antd";
import { Fragment } from "react";
import { Input } from "antd";
import { LoginOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { Row } from "antd";
import { Spin } from "antd";
import { Typography } from "antd";
import { useCallback } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ErrorComponent from "../components/error.component";
import Global from "../../global/global";
import LoadComponent from "../components/load.component";
import logo from "../../assets/logo_azul.svg";
import UsuarioContext from "../../provider/usuario-provider";

function LoginPage() {
  const [_load, _setLoad] = useState<boolean>(false);
  const [_modal, _contextHolder] = Modal.useModal();
  const [_mounted, _setMounted] = useState<any>(null);
  const _apiUsuario = useContext<APIUsuario>(UsuarioContext);
  const { Title } = Typography;
  let _navigate = useNavigate();

  const _getCurrentUser = useCallback(async () => {
    if (localStorage.getItem("Global.USER_SESSION")) {
      _navigate("/");
    }
  }, [_navigate]);

  const _login = async (_values: any) => {
    _setLoad(true);
    let _result: APIResponse = await _apiUsuario._login(
      _values["correo"],
      _values["password"]
    );
    if (_result.success) {
      _modal.success({
        title: Global.NOMBRE_PROYECTO,
        content: _result.message,
        centered: true,
        direction: "ltr",
        onOk: () => {
          _setLoad(false);
          _navigate("/");
        },
      });
    } else {
      _modal.error({
        title: Global.NOMBRE_PROYECTO,
        content: _result.message,
        centered: true,
        direction: "ltr",
        onOk: () => {
          _setLoad(false);
        },
      });
    }
  };

  useEffect(() => {
    _setMounted(true);
    _getCurrentUser();
    return () => {
      _setMounted(false);
    };
  }, [_getCurrentUser]);

  if (_mounted === null) {
    return <LoadComponent />;
  }

  if (!_mounted) {
    return <ErrorComponent />;
  }

  return (
    <Fragment>
      <div className="container-login">
        <Row className="card-login">
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <img src={logo} alt="3AAMSEQ" title="3AAMSEQ" loading="lazy" />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Title level={4}>
              GESTI&Oacute;N DE DOCUMENTOS - ADMINISTRADOR
            </Title>
            <Title level={5}>
              Inicio de sesi&oacute;n <br />
              Ingresa los datos solicitados para iniciar sesi&oacute;n
            </Title>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Form
              name="form-login"
              className="form-login"
              layout="vertical"
              onFinish={_login}
            >
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <Form.Item
                    label="Correo"
                    name="correo"
                    rules={[
                      {
                        required: true,
                        message: "¡Por favor ingresa tu correo!",
                        type: "email",
                      },
                    ]}
                  >
                    <Input
                      allowClear
                      size="middle"
                      readOnly={_load ? true : false}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <Form.Item
                    label="Contraseña"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "¡Por favor ingresa tu contraseña!",
                      },
                    ]}
                  >
                    <Input.Password
                      allowClear
                      size="middle"
                      readOnly={_load ? true : false}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <Form.Item>
                    {_load ? (
                      <Spin
                        className="spin-yellow"
                        tip="Validando los datos ingresados..."
                      />
                    ) : (
                      <Button
                        htmlType="submit"
                        className="button-yellow"
                        icon={<LoginOutlined />}
                      >
                        Iniciar sesi&oacute;n
                      </Button>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <span>
                    El acceso al sistema estar&aacute; disponible de 7:00 a. m.
                    a 7:00 p. m.
                  </span>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        {_contextHolder}
      </div>
    </Fragment>
  );
}

export default LoginPage;
