import { _getSession } from "./dashboard.page";
import { APIArea } from "../../api/api_area";
import { APIProceso } from "../../api/api_proceso";
import { APIResponse } from "../../model/apiresponse";
import { Area } from "../../model/area";
import { Breadcrumb } from "antd";
import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { Col } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { EditOutlined } from "@ant-design/icons";
import { FilterOutlined } from "@ant-design/icons";
import { Fragment } from "react";
import { Input } from "antd";
import { Modal } from "antd";
import { notification } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Proceso } from "../../model/proceso";
import { ReloadOutlined } from "@ant-design/icons";
import { Row } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Select } from "antd";
import { Space } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import { useCallback } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import AreaContext from "../../provider/area-provider";
import ErrorComponent from "../components/error.component";
import Global from "../../global/global";
import LoadComponent from "../components/load.component";
import ProcesoContext from "../../provider/proceso-provider";
import ProcesosEditarWidget from "../widgets/procesos.editar.widget";
import ProcesosListarWidget from "../widgets/procesos.listar.widget";
import ProcesosRegistrarWidget from "../widgets/procesos.registrar.widget";

function ProcesosPage() {
  const [_listaAreas, _setListaAreas] = useState<Area[]>([]);
  const [_loading, _setLoading] = useState<boolean>(true);
  const [_modal, _contextHolder] = Modal.useModal();
  const [_mounted, _setMounted] = useState<any>(null);
  const [_proceso, _setProceso] = useState<string>("");
  const [_procesoEditar, _setProcesoEditar] = useState<Proceso>();
  const [_procesos, _setProcesos] = useState<Proceso[]>([]);
  const [_procesosTemp, _setProcesosTemp] = useState<Proceso[]>([]);
  const [_visibleEditar, _setVisibleEditar] = useState<boolean>(false);
  const [_visibleNuevo, _setVisibleNuevo] = useState<boolean>(false);
  const _apiArea = useContext<APIArea>(AreaContext);
  const _apiProceso = useContext<APIProceso>(ProcesoContext);
  const { _token } = _getSession();
  const { Option } = Select;
  const { Title } = Typography;
  let _navigate = useNavigate();

  const _listarAreas = useCallback(async () => {
    let _result: APIResponse = await _apiArea._listarAreas(_token, 1);
    if (_result.codigo === 401) {
      _modal.error({
        title: Global.NOMBRE_PROYECTO,
        content: _result.message,
        centered: true,
        onOk: () => {
          localStorage.clear();
          _navigate("/login");
        },
      });
    } else if (_result.success) {
      _setListaAreas(_result.data);
    } else {
      _setListaAreas([]);
    }
  }, [_apiArea, _modal, _navigate, _token]);

  const _listarProcesos = useCallback(async () => {
    _setLoading(true);
    _setProcesos([]);
    _setProcesosTemp([]);
    let _result: APIResponse = await _apiProceso._listarProcesos(_token, 2);
    if (_result.codigo === 401) {
      _modal.error({
        title: Global.NOMBRE_PROYECTO,
        content: _result.message,
        centered: true,
        onOk: () => {
          localStorage.clear();
          _navigate("/login");
        },
      });
    } else if (_result.success) {
      _setProcesos(_result.data);
      _setProcesosTemp(_result.data);
    } else {
      _modal.error({
        title: Global.NOMBRE_PROYECTO,
        content: _result.message,
        centered: true,
      });
      _setProcesos([]);
      _setProcesosTemp([]);
    }
    _setLoading(false);
  }, [_apiProceso, _modal, _navigate, _token]);

  const _filtrarEstado = () => ({
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
        <Select
          placeholder="Selecciona un estado"
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(_input: any, _option: any) =>
            _option.children.toLowerCase().indexOf(_input.toLowerCase()) >= 0
          }
          filterSort={(_optionA: any, _optionB: any) =>
            _optionA.children
              .toLowerCase()
              .localeCompare(_optionB.children.toLowerCase())
          }
          style={{ width: "250px" }}
          onChange={(_value: number, _option: any) => {
            if (_value) {
              let _procesosFound: Proceso[] = [];
              for (let i = 0; i < _procesosTemp.length; i++) {
                if (_procesosTemp[i].estado === _value) {
                  _procesosFound.push(_procesosTemp[i]);
                }
              }
              _setProcesos(_procesosFound);
            } else {
              _setProcesos(_procesosTemp);
            }
          }}
        >
          <Option key="estado_activo" value={1}>
            Activo
          </Option>
          <Option key="estado_inactivo" value={0}>
            Inactivo
          </Option>
        </Select>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <FilterOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  const _filtrarArea = () => ({
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
        <Select
          placeholder="Selecciona o busca un área"
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(_input: any, _option: any) =>
            _option.children.toLowerCase().indexOf(_input.toLowerCase()) >= 0
          }
          filterSort={(_optionA: any, _optionB: any) =>
            _optionA.children
              .toLowerCase()
              .localeCompare(_optionB.children.toLowerCase())
          }
          style={{ width: "250px" }}
          onChange={(_value: any, _option: any) => {
            if (_value) {
              let _procesosFound: Proceso[] = [];
              for (let i = 0; i < _procesosTemp.length; i++) {
                if (
                  _procesosTemp[i].area.area
                    .toString()
                    .toUpperCase()
                    .indexOf(_option.children.toUpperCase()) > -1
                ) {
                  _procesosFound.push(_procesosTemp[i]);
                }
              }
              _setProcesos(_procesosFound);
            } else {
              _setProcesos(_procesosTemp);
            }
          }}
        >
          {_listaAreas.map((_area: Area, _index: number) => (
            <Option key={`${_area.codigo}_${_index}`} value={_area.codigo}>
              {_area.area}
            </Option>
          ))}
        </Select>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <FilterOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  const _buscarProceso = () => ({
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
        <Input
          value={_proceso}
          onChange={(e) => _setProceso(e.target.value)}
          onPressEnter={() => _buscar(_proceso)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            onClick={() => _buscar(_proceso)}
            icon={<SearchOutlined />}
            size="small"
            className="button-info"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => _limpiar()}
            icon={<CloseOutlined />}
            size="small"
            danger
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  const _buscar = (_proceso: string) => {
    if (_proceso.length > 0) {
      let _procesosFound: Proceso[] = [];
      for (let i = 0; i < _procesosTemp.length; i++) {
        if (
          _procesosTemp[i].proceso
            .toUpperCase()
            .indexOf(_proceso.toUpperCase()) > -1
        ) {
          _procesosFound.push(_procesosTemp[i]);
        }
      }
      _setProcesos(_procesosFound);
    }
  };

  const _limpiar = () => {
    _setProceso("");
    _setProcesos(_procesosTemp);
  };

  const _renderEstado = (_value: number, _row: Proceso, _index: number) => {
    return (
      <Space>
        <div
          key={`switch${_row.codigo}`}
          className={_value === 1 ? "div-status-active" : "div-status-inactive"}
        />
      </Space>
    );
  };

  const _renderAcciones = (_value: number, _row: Proceso, _index: number) => {
    return _value === 1 ? (
      <Space>
        <EditOutlined
          className="icon-edit"
          onClick={() => {
            _setProcesoEditar(_row);
            _abrirEditar();
          }}
        />
        <DeleteOutlined
          className="icon-delete"
          onClick={() => {
            _cambiarEstadoProceso(_row.codigo, 0);
          }}
        />
      </Space>
    ) : (
      <Space>
        <SyncOutlined
          className="icon-edit"
          onClick={() => {
            _cambiarEstadoProceso(_row.codigo, 1);
          }}
        />
      </Space>
    );
  };

  const _cambiarEstadoProceso = useCallback(
    async (_codigo: number, _estado: number) => {
      let _result: APIResponse = await _apiProceso._cambiarEstadoProceso(
        _codigo,
        _estado,
        _token
      );
      if (_result.codigo === 401) {
        _modal.error({
          title: Global.NOMBRE_PROYECTO,
          content: _result.message,
          centered: true,
          onOk: () => {
            localStorage.clear();
            _navigate("/login");
          },
        });
      } else if (_result.success) {
        notification.success({
          message: Global.NOMBRE_PROYECTO,
          description: _result.message,
          duration: 2,
        });
      } else {
        notification.error({
          message: Global.NOMBRE_PROYECTO,
          description: _result.message,
          duration: 2,
        });
      }
      _listarProcesos();
    },
    [_apiProceso, _listarProcesos, _modal, _navigate, _token]
  );

  const _abrirNuevo = () => {
    _setVisibleNuevo(true);
  };

  const _cerrarNuevo = () => {
    _setVisibleNuevo(false);
  };

  const _abrirEditar = () => {
    _setVisibleEditar(true);
  };

  const _cerrarEditar = () => {
    _setVisibleEditar(false);
  };

  useEffect(() => {
    _setMounted(true);
    _listarAreas();
    _listarProcesos();
    return () => {
      _setMounted(false);
    };
  }, [_listarAreas, _listarProcesos]);

  if (_mounted === null) {
    return <LoadComponent />;
  }

  if (!_mounted) {
    return <ErrorComponent />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Breadcrumb>
            <Breadcrumb.Item>Inicio</Breadcrumb.Item>
            <Breadcrumb.Item>
              <a href="/procesos">Procesos</a>
            </Breadcrumb.Item>
          </Breadcrumb>
          <hr />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Title level={3}>Procesos</Title>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className="mb-10">
          <Space>
            <Button
              icon={<PlusOutlined />}
              className="button-success"
              onClick={_abrirNuevo}
            >
              Agregar
            </Button>
            <Button
              icon={<ReloadOutlined />}
              className="button-info"
              onClick={() => {
                _listarProcesos();
              }}
            >
              Recargar
            </Button>
          </Space>
        </Col>
        {/* Lista de procesos */}
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <ProcesosListarWidget
            _procesos={_procesos}
            _buscarProceso={_buscarProceso}
            _filtrarArea={_filtrarArea}
            _loading={_loading}
            _renderAcciones={_renderAcciones}
            _renderEstado={_renderEstado}
            _filtrarEstado={_filtrarEstado}
          />
        </Col>
      </Row>
      {/* Nuevo registro */}
      <Modal
        title={<b>Nuevo registro</b>}
        visible={_visibleNuevo}
        centered={true}
        closable={false}
        footer={[]}
      >
        <ProcesosRegistrarWidget
          _apiProceso={_apiProceso}
          _listaAreas={_listaAreas}
          _cerrarNuevo={_cerrarNuevo}
          _listarProcesos={_listarProcesos}
          _token={_token}
        />
      </Modal>
      {/* Editar registro */}
      <Modal
        title={<b>Editar registro</b>}
        visible={_visibleEditar}
        centered={true}
        closable={false}
        footer={[]}
      >
        <ProcesosEditarWidget
          _procesoEditar={_procesoEditar!}
          _listaAreas={_listaAreas}
          _listarProcesos={_listarProcesos}
          _cerrarEditar={_cerrarEditar}
          _apiProceso={_apiProceso}
          _token={_token}
        />
      </Modal>
      {_contextHolder}
    </Fragment>
  );
}

export default ProcesosPage;
