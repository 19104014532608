import { APIDocumento } from "../../api/api_documento";
import { APIResponse } from "../../model/apiresponse";
import { Button } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Col } from "antd";
import { DatePicker } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { Empresa } from "../../model/empresa";
import { Form } from "antd";
import { Fragment } from "react";
import { Input } from "antd";
import { Modal } from "antd";
import { Row } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { Select } from "antd";
import { Space } from "antd";
import { Spin } from "antd";
import { Subproceso } from "../../model/subproceso";
import { Tipo } from "../../model/tipo";
import { useState } from "react";
import Global from "../../global/global";
import locale from "antd/es/date-picker/locale/es_ES";

function DocumentosRegistrarWidget({
  _apiDocumento,
  _cerrarNuevo,
  _listarDocumentos,
  _listaSubprocesos,
  _listaTipos,
  _listaEmpresas,
  _token,
}: {
  _apiDocumento: APIDocumento;
  _cerrarNuevo: Function;
  _listarDocumentos: Function;
  _listaSubprocesos: Subproceso[];
  _listaTipos: Tipo[];
  _listaEmpresas: Empresa[];
  _token: string;
}) {
  const [_fileName, _setFileName] = useState<string>(
    "Ningún archivo seleccionado"
  );
  const [_files, _setFiles] = useState<File[]>([]);
  const [_form] = Form.useForm();
  const [_load, _setLoad] = useState<boolean>(false);
  const [_modal, _contextHolder] = Modal.useModal();
  const { Option } = Select;

  const _registrarDocumento = async (_values: any) => {
    _setLoad(true);
    _modal.confirm({
      title: Global.NOMBRE_PROYECTO,
      content: "¿Está seguro de registrar este documento?",
      centered: true,
      okText: "Si, registrar",
      cancelText: "No, cancelar",
      onCancel: () => {
        _setLoad(false);
      },
      onOk: async () => {
        let _rutas: string[] = [];
        for (let _i: number = 0; _i < _files!.length; _i++) {
          let _formData: FormData = new FormData();
          _formData.append("file", _files![_i]);
          _formData.append("token", _token);
          _formData.append("flag", "false");
          let _result: APIResponse = await _apiDocumento._subirArchivo(
            _formData
          );
          if (_result.success) {
            _rutas.push(_result.message!);
          } else {
            _modal.error({
              title: Global.NOMBRE_PROYECTO,
              content: _result.message,
              centered: true,
              onOk: () => {
                _setLoad(false);
              },
            });
            return;
          }
        }
        _form.setFieldsValue({
          archivos: _rutas,
        });
        let _result: APIResponse = await _apiDocumento._registrarDocumento(
          _form.getFieldsValue(),
          _token
        );
        if (_result.success) {
          _modal.success({
            title: Global.NOMBRE_PROYECTO,
            content: _result.message,
            centered: true,
            onOk: () => {
              _form.resetFields();
              _setFileName("Ningún archivo seleccionado");
              _setFiles([]);
              _setLoad(false);
              _listarDocumentos();
              _cerrarNuevo();
            },
          });
        } else {
          _modal.error({
            title: Global.NOMBRE_PROYECTO,
            content: _result.message,
            centered: true,
            onOk: () => {
              _setLoad(false);
            },
          });
        }
      },
    });
  };

  const _onChangeFile = (_e: any) => {
    if (_e.target.files.length >= 1) {
      for (let _i: number = 0; _i < _e.target.files.length; _i++) {
        _files.push(_e.target.files[_i]);
      }
      _setFiles(_files);
      let _names: string[] = [];
      for (let _i: number = 0; _i < _e.target.files.length; _i++) {
        _names.push(_e.target.files[_i].name);
      }
      _setFileName(_names.join(" | "));
    }
  };

  const _deleteFile = (_archivo: File) => {
    let _filesTemp: File[] = _files.filter(
      (_file: File, _index: number) => _file.name !== _archivo.name
    );
    if (_filesTemp.length === 0) {
      _setFileName("Ningún archivo seleccionado");
    }
    _setFiles(_filesTemp);
  };

  return (
    <Fragment>
      <Form
        name="form-nuevo-documento"
        className="form-nuevo-documento"
        form={_form}
        layout="vertical"
        onFinish={_registrarDocumento}
        autoComplete="on"
      >
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Form.Item
              label="Código"
              name="codigo"
              rules={[
                {
                  required: true,
                  message: "¡Por favor ingresa el código del documento!",
                },
              ]}
            >
              <Input allowClear size="middle" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Form.Item
              label="Tipo"
              name="tipo"
              rules={[
                {
                  required: true,
                  message: "¡Por favor selecciona el tipo del documento!",
                },
              ]}
            >
              <Select
                placeholder="Elija una opción"
                allowClear
                showSearch
                optionFilterProp="children"
                filterOption={(_input: any, _option: any) =>
                  _option.children
                    .toLowerCase()
                    .indexOf(_input.toLowerCase()) >= 0
                }
                filterSort={(_optionA: any, _optionB: any) =>
                  _optionA.children
                    .toLowerCase()
                    .localeCompare(_optionB.children.toLowerCase())
                }
              >
                {_listaTipos.map((_tipo: Tipo, _index: number) => (
                  <Option
                    key={`${_tipo.codigo}_${_index}`}
                    value={_tipo.codigo}
                  >
                    {_tipo.tipo}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Form.Item
              label="Versión"
              name="version"
              rules={[
                {
                  required: true,
                  message: "¡Por favor ingresa la versión del documento!",
                },
              ]}
            >
              <Input
                allowClear
                size="middle"
                type="number"
                onWheel={(_e: any) => _e.target.blur()}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Form.Item
              label="Nombre"
              name="nombre"
              rules={[
                {
                  required: true,
                  message: "¡Por favor ingresa el nombre del documento!",
                },
              ]}
            >
              <Input allowClear size="middle" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Form.Item
              label="Fecha de vigencia"
              name="vigencia"
              rules={[
                {
                  required: true,
                  message:
                    "¡Por favor ingresa la fecha de vigencia del documento!",
                },
              ]}
            >
              <DatePicker
                style={{ width: "100%", margin: "4px 1%" }}
                allowClear
                locale={locale}
                format={"YYYY-MM-DD"}
                inputReadOnly
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Form.Item
              label="Archivos (PDF, EXCEL, WORD, IMAGEN)"
              name="archivos"
              rules={
                _files.length < 1
                  ? [
                      {
                        required: true,
                        message: "¡Por favor sube al menos un archivo!",
                      },
                    ]
                  : []
              }
            >
              <label className="custom-label">
                {_fileName}
                <Input
                  allowClear
                  type="file"
                  hidden
                  onChange={_onChangeFile}
                  accept=".doc, .docx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/pdf, image/*"
                  multiple
                />
              </label>
            </Form.Item>
            <Row className="center-text mb-10">
              {_files.map((_archivo: File, _index: number) => {
                return (
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    key={`col_files_${_index}`}
                  >
                    <Space key={`file_${_index}`}>
                      {_archivo.name.replace(/[0-9]/g, "")}
                      <DeleteOutlined
                        key={`icon_${_index}`}
                        onClick={() => {
                          _deleteFile(_archivo);
                        }}
                      />
                    </Space>
                  </Col>
                );
              })}
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Form.Item
              label="Subproceso"
              name="subproceso"
              rules={[
                {
                  required: true,
                  message: "¡Por favor selecciona el subproceso del documento!",
                },
              ]}
            >
              <Select
                placeholder="Elija una opción"
                allowClear
                showSearch
                optionFilterProp="children"
                filterOption={(_input: any, _option: any) =>
                  _option.children
                    .toLowerCase()
                    .indexOf(_input.toLowerCase()) >= 0
                }
                filterSort={(_optionA: any, _optionB: any) =>
                  _optionA.children
                    .toLowerCase()
                    .localeCompare(_optionB.children.toLowerCase())
                }
              >
                {_listaSubprocesos.map(
                  (_subproceso: Subproceso, _index: number) => (
                    <Option
                      key={`${_subproceso.codigo}_${_index}`}
                      value={_subproceso.codigo}
                    >
                      {`${_subproceso.proceso.area.area} | ${_subproceso.subproceso}`}
                    </Option>
                  )
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Form.Item
              label="Empresa"
              name="empresa"
              rules={[
                {
                  required: true,
                  message: "¡Por favor selecciona la empresa del documento!",
                },
              ]}
            >
              <Select placeholder="Elija una opción" allowClear>
                {_listaEmpresas.map((_empresa: Empresa, _index: number) => (
                  <Option
                    key={`${_empresa.codigo}_${_index}`}
                    value={_empresa.codigo}
                  >
                    {_empresa.empresa}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            xxl={24}
            className="center-text"
          >
            <Form.Item>
              {_load ? (
                <Spin
                  className="spin-yellow"
                  tip="Validando los datos ingresados..."
                />
              ) : (
                <Space>
                  <Button
                    htmlType="submit"
                    className="button-success"
                    icon={<SaveOutlined />}
                  >
                    Registrar
                  </Button>
                  <Button
                    className="button-danger"
                    icon={<CloseCircleOutlined />}
                    onClick={() => {
                      _setFiles([]);
                      _setFileName("Ningún archivo seleccionado");
                      _cerrarNuevo();
                    }}
                  >
                    Cancelar
                  </Button>
                </Space>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {_contextHolder}
    </Fragment>
  );
}

export default DocumentosRegistrarWidget;
