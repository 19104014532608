import { Proceso } from "../../model/proceso";
import { Col } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { EditOutlined } from "@ant-design/icons";
import { Fragment } from "react";
import { Row } from "antd";
import { Space } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import { Table } from "antd";
import { Area } from "../../model/area";

function ProcesosListarWidget({
  _procesos,
  _buscarProceso,
  _filtrarArea,
  _filtrarEstado,
  _loading,
  _renderAcciones,
  _renderEstado,
}: {
  _procesos: Proceso[];
  _buscarProceso: Function;
  _filtrarArea: Function;
  _filtrarEstado: Function;
  _loading: boolean;
  _renderAcciones: Function;
  _renderEstado: Function;
}) {
  return (
    <Fragment>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className="mb-10">
          Leyenda (Acciones):&nbsp;&nbsp;
          <Space>
            <EditOutlined className="icon-edit" /> Editar&nbsp;&nbsp;
            <DeleteOutlined className="icon-delete" /> Borrar&nbsp;&nbsp;
            <SyncOutlined className="icon-edit" /> Activar&nbsp;&nbsp;
          </Space>
          <br />
          Leyenda (Estado):&nbsp;&nbsp;
          <Space>
            <div className="div-status-active" /> Activo&nbsp;&nbsp;
            <div className="div-status-inactive" /> Inactivo&nbsp;&nbsp;
          </Space>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Table
            bordered
            key="procesos"
            size="small"
            rowKey="codigo"
            columns={[
              {
                title: "Estado",
                dataIndex: "estado",
                key: "estado",
                width: "10%",
                align: "center",
                ..._filtrarEstado(),
                render: _renderEstado,
                sorter: (a: Proceso, b: Proceso) => a.estado - b.estado,
              },
              {
                title: "Proceso",
                dataIndex: "proceso",
                key: "proceso",
                width: "50%",
                align: "center",
                ..._buscarProceso(),
                sorter: (a: Proceso, b: Proceso) =>
                  a.proceso.localeCompare(b.proceso),
              },
              {
                title: "Área",
                dataIndex: "area",
                key: "area",
                width: "30%",
                align: "center",
                ..._filtrarArea(),
                sorter: (a: Proceso, b: Proceso) =>
                  a.area.area.localeCompare(b.area.area),
                render: (_value: Area, _row: Proceso, _index: number) => {
                  return _value.area;
                },
              },
              {
                title: "Acciones",
                dataIndex: "estado",
                key: "estado",
                width: "10%",
                align: "center",
                render: _renderAcciones,
              },
            ]}
            pagination={{
              pageSize: 20,
              simple: false,
            }}
            loading={_loading}
            dataSource={_procesos.map((proceso: Proceso) => ({
              ...proceso,
            }))}
          />
        </Col>
      </Row>
    </Fragment>
  );
}

export default ProcesosListarWidget;
