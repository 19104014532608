import { Empresa } from "./empresa";
import { Perfil } from "./perfil";
import { Puesto } from "./puesto";
import { Sede } from "./sede";

export interface UsuarioDataI {
  codigo: number;
  nombres: string;
  correo: string;
  sede: Sede;
  perfil: Perfil;
  puesto: Puesto;
  empresa: Empresa;
}
export class Usuario implements UsuarioDataI {
  codigo: number;
  nombres: string;
  correo: string;
  sede: Sede;
  perfil: Perfil;
  puesto: Puesto;
  empresa: Empresa;

  constructor(data: UsuarioDataI) {
    this.codigo = data.codigo;
    this.nombres = data.nombres;
    this.correo = data.correo;
    this.sede = data.sede;
    this.perfil = data.perfil;
    this.puesto = data.puesto;
    this.empresa = data.empresa;
  }
}
