import { Button } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import { CloseOutlined } from "@ant-design/icons";
import { Col } from "antd";
import { DatePicker } from "antd";
import { Documento } from "../../model/documento";
import { FilterOutlined } from "@ant-design/icons";
import { Fragment } from "react";
import { Input } from "antd";
import { Puesto } from "../../model/puesto";
import { Row } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Sede } from "../../model/sede";
import { Select } from "antd";
import { Space } from "antd";
import { Table } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { Usuario } from "../../model/usuario";
import { UsuarioDescarga } from "../../model/usuario-descarga";
import locale from "antd/es/date-picker/locale/es_ES";
import Moment from "moment";

function DocumentosListarUsuariosWidget({
  _documentoSeleccionado,
}: {
  _documentoSeleccionado: Documento;
}) {
  const [_listaPuestos, _setListaPuestos] = useState<Puesto[]>([]);
  const [_listaSedes, _setListaSedes] = useState<Sede[]>([]);
  const [_loading, _setLoading] = useState<boolean>(true);
  const [_usuario, _setUsuario] = useState<string>("");
  const [_usuarios, _setUsuarios] = useState<UsuarioDescarga[]>([]);
  const [_usuariosTemp, _setUsuariosTemp] = useState<UsuarioDescarga[]>([]);
  const { Option } = Select;

  const _getSedes = (_documento: Documento) => {
    let _sedesTemp: Sede[] = [];
    _documento.usuarios.forEach((_value: UsuarioDescarga, _index: number) => {
      _sedesTemp.push(_value.usuario.sede);
    });
    _setListaSedes(_sedesTemp);
  };

  const _getPuestos = (_documento: Documento) => {
    let _puestosTemp: Puesto[] = [];
    _documento.usuarios.forEach((_value: UsuarioDescarga, _index: number) => {
      _puestosTemp.push(_value.usuario.puesto);
    });
    _setListaPuestos(_puestosTemp);
  };

  const _filtrarSede = (_sedes: Sede[]) => ({
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
        <Select
          placeholder="Selecciona o busca una sede"
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(_input: any, _option: any) =>
            _option.children.toLowerCase().indexOf(_input.toLowerCase()) >= 0
          }
          filterSort={(_optionA: any, _optionB: any) =>
            _optionA.children
              .toLowerCase()
              .localeCompare(_optionB.children.toLowerCase())
          }
          style={{ width: "250px" }}
          onChange={(_value: any, _option: any) => {
            if (_value) {
              let _usuariosFound: UsuarioDescarga[] = [];
              for (let i = 0; i < _usuariosTemp.length; i++) {
                if (
                  _usuariosTemp[i].usuario.sede.sede
                    .toString()
                    .toUpperCase()
                    .indexOf(_option.children.toUpperCase()) > -1
                ) {
                  _usuariosFound.push(_usuariosTemp[i]);
                }
              }
              _setUsuarios(_usuariosFound);
            } else {
              _setUsuarios(_usuariosTemp);
            }
          }}
        >
          {_sedes.map((_sede: Sede, _index: number) => (
            <Option key={`${_sede.codigo}_${_index}`} value={_sede.codigo}>
              {_sede.sede}
            </Option>
          ))}
        </Select>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <FilterOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  const _filtrarPuesto = (_puestos: Puesto[]) => ({
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
        <Select
          placeholder="Selecciona o busca un puesto"
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(_input: any, _option: any) =>
            _option.children.toLowerCase().indexOf(_input.toLowerCase()) >= 0
          }
          filterSort={(_optionA: any, _optionB: any) =>
            _optionA.children
              .toLowerCase()
              .localeCompare(_optionB.children.toLowerCase())
          }
          style={{ width: "250px" }}
          onChange={(_value: any, _option: any) => {
            if (_value) {
              let _usuariosFound: UsuarioDescarga[] = [];
              for (let i = 0; i < _usuariosTemp.length; i++) {
                if (
                  _usuariosTemp[i].usuario.puesto.puesto
                    .toString()
                    .toUpperCase()
                    .indexOf(_option.children.toUpperCase()) > -1
                ) {
                  _usuariosFound.push(_usuariosTemp[i]);
                }
              }
              _setUsuarios(_usuariosFound);
            } else {
              _setUsuarios(_usuariosTemp);
            }
          }}
        >
          {_puestos.map((_puesto: Puesto, _index: number) => (
            <Option key={`${_puesto.codigo}_${_index}`} value={_puesto.codigo}>
              {_puesto.puesto}
            </Option>
          ))}
        </Select>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <FilterOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  const _buscarUsuarioPorFecha = (_tipo: string) => ({
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
        <label>&nbsp;Fecha:</label>
        <DatePicker
          style={{ width: "100%", margin: "4px 1%" }}
          allowClear
          locale={locale}
          onChange={(_moment: any, _date: any) => {
            _buscarPorFecha(_moment, _date, _tipo);
          }}
          format={"YYYY-MM-DD"}
        />
      </div>
    ),
    filterIcon: (filtered: any) => (
      <CalendarOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  const _buscarPorFecha = (_moment: any, _date: any, _tipo: string) => {
    _setLoading(true);
    if (!_date) {
      _setUsuarios(_usuariosTemp);
    } else {
      let _usuariosFound: UsuarioDescarga[] = [];
      _usuariosTemp.forEach((_value: UsuarioDescarga) => {
        let _fecha: string =
          _tipo === "fecha_permiso"
            ? _value.fecha_permiso?.substring(0, 10)!
            : _value.fecha_descarga?.substring(0, 10)!;
        if (_fecha.indexOf(_date) > -1) {
          _usuariosFound.push(_value);
        }
      });
      _setUsuarios(_usuariosFound);
    }
    _setLoading(false);
  };

  const _filtrarEstado = () => ({
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
        <Select
          placeholder="Selecciona un estado"
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(_input: any, _option: any) =>
            _option.children.toLowerCase().indexOf(_input.toLowerCase()) >= 0
          }
          filterSort={(_optionA: any, _optionB: any) =>
            _optionA.children
              .toLowerCase()
              .localeCompare(_optionB.children.toLowerCase())
          }
          style={{ width: "250px" }}
          onChange={(_value: number, _option: any) => {
            if (_value) {
              let _usuariosFound: UsuarioDescarga[] = [];
              for (let i = 0; i < _usuariosTemp.length; i++) {
                if (_usuariosTemp[i].descargado === _value) {
                  _usuariosFound.push(_usuariosTemp[i]);
                }
              }
              _setUsuarios(_usuariosFound);
            } else {
              _setUsuarios(_usuariosTemp);
            }
          }}
        >
          <Option key="estado_descargado" value={1}>
            Descargado
          </Option>
          <Option key="estado_no_descargado" value={0}>
            No descargado
          </Option>
        </Select>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <FilterOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  const _buscarUsuario = () => ({
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
        <Input
          value={_usuario}
          onChange={(e) => _setUsuario(e.target.value)}
          onPressEnter={() => _buscarPorNombre(_usuario)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            onClick={() => _buscarPorNombre(_usuario)}
            icon={<SearchOutlined />}
            size="small"
            className="button-info"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              _setUsuario("");
              _setUsuarios(_usuariosTemp);
            }}
            icon={<CloseOutlined />}
            size="small"
            danger
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  const _buscarPorNombre = (_usuario: string) => {
    if (_usuario.length > 0) {
      let _usuariosFound: UsuarioDescarga[] = [];
      for (let i = 0; i < _usuariosTemp.length; i++) {
        if (
          _usuariosTemp[i].usuario.nombres
            .toUpperCase()
            .indexOf(_usuario.toUpperCase()) > -1
        ) {
          _usuariosFound.push(_usuariosTemp[i]);
        }
      }
      _setUsuarios(_usuariosFound);
    }
  };

  useEffect(() => {
    _getSedes(_documentoSeleccionado);
    _getPuestos(_documentoSeleccionado);
    _setUsuarios(_documentoSeleccionado.usuarios);
    _setUsuariosTemp(_documentoSeleccionado.usuarios);
    _setLoading(false);
  }, [_documentoSeleccionado]);

  return (
    <Fragment>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className="mb-10">
          Leyenda:&nbsp;&nbsp;
          <Space>
            <div className="div-status-active" /> Descargado&nbsp;&nbsp;
            <div className="div-status-inactive" /> No descargado&nbsp;&nbsp;
          </Space>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Table
            bordered
            key="documentos"
            size="small"
            rowKey="codigo"
            columns={[
              {
                title: "Estado",
                dataIndex: "descargado",
                key: "descargado",
                width: "5%",
                align: "center",
                ..._filtrarEstado(),
                render: (
                  _value: number,
                  _row: UsuarioDescarga,
                  _index: number
                ) => {
                  return (
                    <Space>
                      <div
                        key={`descargado${_row.codigo}`}
                        className={
                          _value === 1
                            ? "div-status-active"
                            : "div-status-inactive"
                        }
                      />
                    </Space>
                  );
                },
                sorter: (a: UsuarioDescarga, b: UsuarioDescarga) =>
                  a.descargado - b.descargado,
              },
              {
                title: "Usuario",
                dataIndex: "usuario",
                key: "usuario",
                width: "25%",
                align: "center",
                ..._buscarUsuario(),
                render: (
                  _value: Usuario,
                  _row: UsuarioDescarga,
                  _index: number
                ) => {
                  return _value.nombres;
                },
                sorter: (a: UsuarioDescarga, b: UsuarioDescarga) =>
                  a.usuario.nombres.localeCompare(b.usuario.nombres),
              },
              {
                title: "Sede",
                dataIndex: "usuario",
                key: "usuario",
                width: "15%",
                align: "center",
                ..._filtrarSede(_listaSedes),
                render: (
                  _value: Usuario,
                  _row: UsuarioDescarga,
                  _index: number
                ) => {
                  return _value.sede.sede;
                },
                sorter: (a: UsuarioDescarga, b: UsuarioDescarga) =>
                  a.usuario.sede.sede.localeCompare(b.usuario.sede.sede),
              },
              {
                title: "Puesto",
                dataIndex: "usuario",
                key: "usuario",
                width: "15%",
                align: "center",
                ..._filtrarPuesto(_listaPuestos),
                render: (
                  _value: Usuario,
                  _row: UsuarioDescarga,
                  _index: number
                ) => {
                  return _value.puesto.puesto;
                },
                sorter: (a: UsuarioDescarga, b: UsuarioDescarga) =>
                  a.usuario.puesto.puesto.localeCompare(
                    b.usuario.puesto.puesto
                  ),
              },
              {
                title: "Fecha permiso",
                dataIndex: "fecha_permiso",
                key: "fecha_permiso",
                width: "20%",
                align: "center",
                ..._buscarUsuarioPorFecha("fecha_permiso"),
                sorter: (a: UsuarioDescarga, b: UsuarioDescarga) =>
                  a.fecha_permiso.localeCompare(b.fecha_permiso),
                render: (
                  _value: Date,
                  _row: UsuarioDescarga,
                  _index: number
                ) => {
                  return Moment(_value).format("DD-MM-YYYY");
                },
              },
              {
                title: "Fecha descarga",
                dataIndex: "fecha_descarga",
                key: "fecha_descarga",
                width: "20%",
                align: "center",
                ..._buscarUsuarioPorFecha("fecha_descarga"),
                sorter: (a: UsuarioDescarga, b: UsuarioDescarga) =>
                  a.fecha_descarga.localeCompare(b.fecha_descarga),
                render: (
                  _value: Date,
                  _row: UsuarioDescarga,
                  _index: number
                ) => {
                  if (_value) {
                    return Moment(_value).format("DD-MM-YYYY");
                  }
                },
              },
            ]}
            pagination={{
              pageSize: 20,
              simple: false,
            }}
            loading={_loading}
            dataSource={_usuarios.map((_usuario: UsuarioDescarga) => ({
              ..._usuario,
            }))}
          />
        </Col>
      </Row>
    </Fragment>
  );
}

export default DocumentosListarUsuariosWidget;
