import axios from "axios";
import Global from "../global/global";
import { APIResponse } from "../model/apiresponse";
import { Empresa } from "../model/empresa";
import { EmpresaDataI } from "../model/empresa";

export class APIEmpresa {
  async _listarEmpresas(_token: string): Promise<APIResponse> {
    let _response: APIResponse = new APIResponse({});
    try {
      let _result: any = await axios.post(`${Global.URL_API}listarEmpresas`, {
        token: _token,
      });
      if (_result.status === 200) {
        if (_result.data.success) {
          let _empresas: Empresa[] = [];
          _result.data.data.forEach((_tipo: EmpresaDataI) => {
            _empresas.push(new Empresa(_tipo));
          });
          _response = {
            success: _result.data.success,
            data: _empresas,
            codigo: _result.data.codigo,
          };
        } else {
          _response = {
            success: _result.data.success,
            message: _result.data.message,
            codigo: _result.data.codigo,
          };
        }
      } else {
        _response = {
          success: false,
          message: "Ocurrió un error de conexión, inténtalo nuevamente",
          codigo: _result.data.codigo,
        };
      }
    } catch (e: any) {
      _response = {
        success: false,
        message: e,
      };
    }
    return _response;
  }
}
