import { _getSession } from "./dashboard.page";
import { APIArea } from "../../api/api_area";
import { APIProceso } from "../../api/api_proceso";
import { APIResponse } from "../../model/apiresponse";
import { APISubproceso } from "../../api/api_subproceso";
import { Area } from "../../model/area";
import { Breadcrumb } from "antd";
import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { Col } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { EditOutlined } from "@ant-design/icons";
import { FilterOutlined } from "@ant-design/icons";
import { Fragment } from "react";
import { Input } from "antd";
import { Modal } from "antd";
import { notification } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Proceso } from "../../model/proceso";
import { ReloadOutlined } from "@ant-design/icons";
import { Row } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Select } from "antd";
import { Space } from "antd";
import { Subproceso } from "../../model/subproceso";
import { SyncOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import { useCallback } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import AreaContext from "../../provider/area-provider";
import ErrorComponent from "../components/error.component";
import Global from "../../global/global";
import LoadComponent from "../components/load.component";
import ProcesoContext from "../../provider/proceso-provider";
import SubprocesoContext from "../../provider/subproceso-provider";
import SubprocesosEditarWidget from "../widgets/subprocesos.editar.widget";
import SubprocesosListarWidget from "../widgets/subprocesos.listar.widget";
import SubprocesosRegistrarWidget from "../widgets/subprocesos.registrar.widget";

function SubprocesosPage() {
  const [_listaAreas, _setListaAreas] = useState<Area[]>([]);
  const [_listaProcesos, _setListaProcesos] = useState<Proceso[]>([]);
  const [_loading, _setLoading] = useState<boolean>(true);
  const [_modal, _contextHolder] = Modal.useModal();
  const [_mounted, _setMounted] = useState<any>(null);
  const [_subproceso, _setSubproceso] = useState<string>("");
  const [_subprocesoEditar, _setProcesoEditar] = useState<Subproceso>();
  const [_subprocesos, _setSubprocesos] = useState<Subproceso[]>([]);
  const [_subprocesosTemp, _setSubprocesosTemp] = useState<Subproceso[]>([]);
  const [_visibleEditar, _setVisibleEditar] = useState<boolean>(false);
  const [_visibleNuevo, _setVisibleNuevo] = useState<boolean>(false);
  const _apiArea = useContext<APIArea>(AreaContext);
  const _apiProceso = useContext<APIProceso>(ProcesoContext);
  const _apiSubproceso = useContext<APISubproceso>(SubprocesoContext);
  const { _token } = _getSession();
  const { Option } = Select;
  const { Title } = Typography;
  let _navigate = useNavigate();

  const _listarAreas = useCallback(async () => {
    let _result: APIResponse = await _apiArea._listarAreas(_token, 1);
    if (_result.codigo === 401) {
      _modal.error({
        title: Global.NOMBRE_PROYECTO,
        content: _result.message,
        centered: true,
        onOk: () => {
          localStorage.clear();
          _navigate("/login");
        },
      });
    } else if (_result.success) {
      _setListaAreas(_result.data);
    } else {
      _setListaAreas([]);
    }
  }, [_apiArea, _modal, _navigate, _token]);

  const _listarProcesos = useCallback(async () => {
    let _result: APIResponse = await _apiProceso._listarProcesos(_token, 1);
    if (_result.codigo === 401) {
      _modal.error({
        title: Global.NOMBRE_PROYECTO,
        content: _result.message,
        centered: true,
        onOk: () => {
          localStorage.clear();
          _navigate("/login");
        },
      });
    } else if (_result.success) {
      _setListaProcesos(_result.data);
    } else {
      _setListaProcesos([]);
    }
  }, [_apiProceso, _modal, _navigate, _token]);

  const _listarSubprocesos = useCallback(async () => {
    _setLoading(true);
    _setSubprocesos([]);
    _setSubprocesosTemp([]);
    let _result: APIResponse = await _apiSubproceso._listarSubprocesos(
      _token,
      2
    );
    if (_result.codigo === 401) {
      _modal.error({
        title: Global.NOMBRE_PROYECTO,
        content: _result.message,
        centered: true,
        onOk: () => {
          localStorage.clear();
          _navigate("/login");
        },
      });
    } else if (_result.success) {
      _setSubprocesos(_result.data);
      _setSubprocesosTemp(_result.data);
    } else {
      _modal.error({
        title: Global.NOMBRE_PROYECTO,
        content: _result.message,
        centered: true,
      });
      _setSubprocesos([]);
      _setSubprocesosTemp([]);
    }
    _setLoading(false);
  }, [_apiSubproceso, _modal, _navigate, _token]);

  const _filtrarEstado = () => ({
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
        <Select
          placeholder="Selecciona un estado"
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(_input: any, _option: any) =>
            _option.children.toLowerCase().indexOf(_input.toLowerCase()) >= 0
          }
          filterSort={(_optionA: any, _optionB: any) =>
            _optionA.children
              .toLowerCase()
              .localeCompare(_optionB.children.toLowerCase())
          }
          style={{ width: "250px" }}
          onChange={(_value: number, _option: any) => {
            if (_value) {
              let _subprocesosFound: Subproceso[] = [];
              for (let i = 0; i < _subprocesosTemp.length; i++) {
                if (_subprocesosTemp[i].estado === _value) {
                  _subprocesosFound.push(_subprocesosTemp[i]);
                }
              }
              _setSubprocesos(_subprocesosFound);
            } else {
              _setSubprocesos(_subprocesosTemp);
            }
          }}
        >
          <Option key="estado_activo" value={1}>
            Activo
          </Option>
          <Option key="estado_inactivo" value={0}>
            Inactivo
          </Option>
        </Select>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <FilterOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  const _filtrarArea = () => ({
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
        <Select
          placeholder="Selecciona o busca un área"
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(_input: any, _option: any) =>
            _option.children.toLowerCase().indexOf(_input.toLowerCase()) >= 0
          }
          filterSort={(_optionA: any, _optionB: any) =>
            _optionA.children
              .toLowerCase()
              .localeCompare(_optionB.children.toLowerCase())
          }
          style={{ width: "250px" }}
          onChange={(_value: any, _option: any) => {
            if (_value) {
              let _subprocesosFound: Subproceso[] = [];
              for (let i = 0; i < _subprocesosTemp.length; i++) {
                if (
                  _subprocesosTemp[i].proceso.area.area
                    .toString()
                    .toUpperCase()
                    .indexOf(_option.children.toUpperCase()) > -1
                ) {
                  _subprocesosFound.push(_subprocesosTemp[i]);
                }
              }
              _setSubprocesos(_subprocesosFound);
            } else {
              _setSubprocesos(_subprocesosTemp);
            }
          }}
        >
          {_listaAreas.map((_area: Area, _index: number) => (
            <Option key={`${_area.codigo}_${_index}`} value={_area.codigo}>
              {_area.area}
            </Option>
          ))}
        </Select>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <FilterOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  const _filtrarProceso = () => ({
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
        <Select
          placeholder="Selecciona o busca un proceso"
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(_input: any, _option: any) =>
            _option.children.toLowerCase().indexOf(_input.toLowerCase()) >= 0
          }
          filterSort={(_optionA: any, _optionB: any) =>
            _optionA.children
              .toLowerCase()
              .localeCompare(_optionB.children.toLowerCase())
          }
          style={{ width: "250px" }}
          onChange={(_value: any, _option: any) => {
            if (_value) {
              let _subprocesosFound: Subproceso[] = [];
              for (let i = 0; i < _subprocesosTemp.length; i++) {
                if (
                  _subprocesosTemp[i].proceso.proceso
                    .toString()
                    .toUpperCase()
                    .indexOf(_option.children.toUpperCase()) > -1
                ) {
                  _subprocesosFound.push(_subprocesosTemp[i]);
                }
              }
              _setSubprocesos(_subprocesosFound);
            } else {
              _setSubprocesos(_subprocesosTemp);
            }
          }}
        >
          {_listaProcesos.map((_proceso: Proceso, _index: number) => (
            <Option
              key={`${_proceso.codigo}_${_index}`}
              value={_proceso.codigo}
            >
              {_proceso.proceso}
            </Option>
          ))}
        </Select>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <FilterOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  const _buscarSubproceso = () => ({
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
        <Input
          value={_subproceso}
          onChange={(e) => _setSubproceso(e.target.value)}
          onPressEnter={() => _buscar(_subproceso)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            onClick={() => _buscar(_subproceso)}
            icon={<SearchOutlined />}
            size="small"
            className="button-info"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => _limpiar()}
            icon={<CloseOutlined />}
            size="small"
            danger
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  const _buscar = (_subproceso: string) => {
    if (_subproceso.length > 0) {
      let _procesosFound: Subproceso[] = [];
      for (let i = 0; i < _subprocesosTemp.length; i++) {
        if (
          _subprocesosTemp[i].subproceso
            .toUpperCase()
            .indexOf(_subproceso.toUpperCase()) > -1
        ) {
          _procesosFound.push(_subprocesosTemp[i]);
        }
      }
      _setSubprocesos(_procesosFound);
    }
  };

  const _limpiar = () => {
    _setSubproceso("");
    _setSubprocesos(_subprocesosTemp);
  };

  const _renderEstado = (_value: number, _row: Subproceso, _index: number) => {
    return (
      <Space>
        <div
          key={`switch${_row.codigo}`}
          className={_value === 1 ? "div-status-active" : "div-status-inactive"}
        />
      </Space>
    );
  };

  const _renderAcciones = (
    _value: number,
    _row: Subproceso,
    _index: number
  ) => {
    return _value === 1 ? (
      <Space>
        <EditOutlined
          className="icon-edit"
          onClick={() => {
            _setProcesoEditar(_row);
            _abrirEditar();
          }}
        />
        <DeleteOutlined
          className="icon-delete"
          onClick={() => {
            _cambiarEstadoSubproceso(_row.codigo, 0);
          }}
        />
      </Space>
    ) : (
      <Space>
        <SyncOutlined
          className="icon-edit"
          onClick={() => {
            _cambiarEstadoSubproceso(_row.codigo, 1);
          }}
        />
      </Space>
    );
  };

  const _cambiarEstadoSubproceso = useCallback(
    async (_codigo: number, _estado: number) => {
      let _result: APIResponse = await _apiSubproceso._cambiarEstadoSubproceso(
        _codigo,
        _estado,
        _token
      );
      if (_result.success) {
        notification.success({
          message: Global.NOMBRE_PROYECTO,
          description: _result.message,
          duration: 2,
        });
      } else {
        notification.error({
          message: Global.NOMBRE_PROYECTO,
          description: _result.message,
          duration: 2,
        });
      }
      _listarSubprocesos();
    },
    [_apiSubproceso, _listarSubprocesos, _token]
  );

  const _abrirNuevo = () => {
    _setVisibleNuevo(true);
  };

  const _cerrarNuevo = () => {
    _setVisibleNuevo(false);
  };

  const _abrirEditar = () => {
    _setVisibleEditar(true);
  };
  const _cerrarEditar = () => {
    _setVisibleEditar(false);
  };

  useEffect(() => {
    _setMounted(true);
    _listarAreas();
    _listarProcesos();
    _listarSubprocesos();
    return () => {
      _setMounted(false);
    };
  }, [_listarAreas, _listarProcesos, _listarSubprocesos]);

  if (_mounted === null) {
    return <LoadComponent />;
  }

  if (!_mounted) {
    return <ErrorComponent />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Breadcrumb>
            <Breadcrumb.Item>Inicio</Breadcrumb.Item>
            <Breadcrumb.Item>
              <a href="/subprocesos">Subprocesos</a>
            </Breadcrumb.Item>
          </Breadcrumb>
          <hr />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Title level={3}>Subprocesos</Title>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className="mb-10">
          <Space>
            <Button
              icon={<PlusOutlined />}
              className="button-success"
              onClick={_abrirNuevo}
            >
              Agregar
            </Button>
            <Button
              icon={<ReloadOutlined />}
              className="button-info"
              onClick={() => {
                _listarSubprocesos();
              }}
            >
              Recargar
            </Button>
          </Space>
        </Col>
        {/* Lista de subprocesos */}
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <SubprocesosListarWidget
            _subprocesos={_subprocesos}
            _buscarSubproceso={_buscarSubproceso}
            _loading={_loading}
            _renderAcciones={_renderAcciones}
            _renderEstado={_renderEstado}
            _filtrarEstado={_filtrarEstado}
            _filtrarProceso={_filtrarProceso}
            _filtrarArea={_filtrarArea}
          />
        </Col>
      </Row>
      {/* Nuevo registro */}
      <Modal
        title={<b>Nuevo registro</b>}
        visible={_visibleNuevo}
        centered={true}
        closable={false}
        footer={[]}
      >
        <SubprocesosRegistrarWidget
          _apiSubproceso={_apiSubproceso}
          _listaProcesos={_listaProcesos}
          _cerrarNuevo={_cerrarNuevo}
          _listarSubprocesos={_listarSubprocesos}
          _token={_token}
        />
      </Modal>
      {/* Editar registro */}
      <Modal
        title={<b>Editar registro</b>}
        visible={_visibleEditar}
        centered={true}
        closable={false}
        footer={[]}
      >
        <SubprocesosEditarWidget
          _subprocesoEditar={_subprocesoEditar!}
          _listaProcesos={_listaProcesos}
          _listarSubprocesos={_listarSubprocesos}
          _cerrarEditar={_cerrarEditar}
          _apiSubproceso={_apiSubproceso}
          _token={_token}
        />
      </Modal>
      {_contextHolder}
    </Fragment>
  );
}

export default SubprocesosPage;
