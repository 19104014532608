export interface PuestoDataI {
  codigo: number;
  puesto: string;
  estado: number;
}

export class Puesto implements PuestoDataI {
  codigo: number;
  puesto: string;
  estado: number;

  constructor(data: PuestoDataI) {
    this.codigo = data.codigo;
    this.puesto = data.puesto;
    this.estado = data.estado;
  }
}
