import { _getSession } from "./dashboard.page";
import { APIDocumento } from "../../api/api_documento";
import { APIResponse } from "../../model/apiresponse";
import { Breadcrumb } from "antd";
import { Button } from "antd";
import { Col } from "antd";
import { Documento } from "../../model/documento";
import { DownloadOutlined } from "@ant-design/icons";
import { Excel } from "antd-table-saveas-excel";
import { Fragment } from "react";
import { Modal } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { FilterOutlined } from "@ant-design/icons";
import { Row } from "antd";
import { Select } from "antd";
import { Space } from "antd";
import { Table } from "antd";
import { Typography } from "antd";
import { useCallback } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Usuario } from "../../model/usuario";
import { UsuarioDescarga } from "../../model/usuario-descarga";
import { useNavigate } from "react-router-dom";
import DocumentoContext from "../../provider/documento-provider";
import ErrorComponent from "../components/error.component";
import Global from "../../global/global";
import LoadComponent from "../components/load.component";
import { APIArea } from "../../api/api_area";
import AreaContext from "../../provider/area-provider";
import { Area } from "../../model/area";
import { Subproceso } from "../../model/subproceso";
import { APISubproceso } from "../../api/api_subproceso";
import SubprocesoContext from "../../provider/subproceso-provider";
import { Tipo } from "../../model/tipo";
import TipoContext from "../../provider/tipo-provider";
import { APITipo } from "../../api/api_tipo";
import Moment from "moment";

function ReporteDifusionPage() {
  const [_documentos, _setDocumentos] = useState<Documento[]>([]);
  const [_loading, _setLoading] = useState<boolean>(true);
  const [_modal, _contextHolder] = Modal.useModal();
  const [_mounted, _setMounted] = useState<any>(null);
  const [_usuarios, _setUsuarios] = useState<UsuarioDescarga[]>([]);
  const [_usuariosTemp, _setUsuariosTemp] = useState<UsuarioDescarga[]>([]);
  const _apiDocumento = useContext<APIDocumento>(DocumentoContext);
  const _apiArea = useContext<APIArea>(AreaContext);
  const _apiTipo = useContext<APITipo>(TipoContext);
  const _apiSubproceso = useContext<APISubproceso>(SubprocesoContext);
  const [_listaAreas, _setListaAreas] = useState<Area[]>([]);
  const [_listaSubprocesos, _setListaSubprocesos] = useState<Subproceso[]>([]);
  const [_listaTipos, _setListaTipos] = useState<Tipo[]>([]);
  const { _token } = _getSession();
  const { Option } = Select;
  const { Title } = Typography;
  let _navigate = useNavigate();

  const _listarAreas = useCallback(async () => {
    let _result: APIResponse = await _apiArea._listarAreas(_token, 1);
    if (_result.codigo === 401) {
      _modal.error({
        title: Global.NOMBRE_PROYECTO,
        content: _result.message,
        centered: true,
        onOk: () => {
          localStorage.clear();
          _navigate("/login");
        },
      });
    } else if (_result.success) {
      _setListaAreas(_result.data);
    } else {
      _setListaAreas([]);
    }
  }, [_apiArea, _modal, _navigate, _token]);

  const _listarSubprocesos = useCallback(async () => {
    let _result: APIResponse = await _apiSubproceso._listarSubprocesos(
      _token,
      1
    );
    if (_result.codigo === 401) {
      _modal.error({
        title: Global.NOMBRE_PROYECTO,
        content: _result.message,
        centered: true,
        onOk: () => {
          localStorage.clear();
          _navigate("/login");
        },
      });
    } else if (_result.success) {
      _setListaSubprocesos(_result.data);
    } else {
      _setListaSubprocesos([]);
    }
  }, [_apiSubproceso, _modal, _navigate, _token]);

  const _listarTipos = useCallback(async () => {
    let _result: APIResponse = await _apiTipo._listarTipos(_token);
    if (_result.codigo === 401) {
      _modal.error({
        title: Global.NOMBRE_PROYECTO,
        content: _result.message,
        centered: true,
        onOk: () => {
          localStorage.clear();
          _navigate("/login");
        },
      });
    } else if (_result.success) {
      _setListaTipos(_result.data);
    } else {
      _setListaTipos([]);
    }
  }, [_apiTipo, _modal, _navigate, _token]);

  const _reporte = (_documentos: Documento[]) => {
    _setLoading(true);
    if (_documentos.length > 0) {
      _setUsuarios([]);
      _setUsuariosTemp([]);
      let _usuariosFound: UsuarioDescarga[] = [];
      _documentos.forEach((_documento: Documento, _index: number) => {
        _documento.usuarios.forEach(
          (_usuario: UsuarioDescarga, _index: number) => {
            _usuariosFound.push(_usuario);
          }
        );
      });
      _setUsuarios(_usuariosFound);
      _setUsuariosTemp(_usuariosFound);
      _setLoading(false);
    }
  };

  const _listarDocumentos = useCallback(async () => {
    _setDocumentos([]);
    let _result: APIResponse = await _apiDocumento._listarDocumentos(
      _token,
      "VIGENTE"
    );
    if (_result.codigo === 401) {
      _modal.error({
        title: Global.NOMBRE_PROYECTO,
        content: _result.message,
        centered: true,
        onOk: () => {
          localStorage.clear();
          _navigate("/login");
        },
      });
    } else if (_result.success) {
      _setDocumentos(_result.data);
      _reporte(_result.data);
    } else {
      _modal.error({
        title: Global.NOMBRE_PROYECTO,
        content: _result.message,
        centered: true,
        onOk: () => {
          _setLoading(false);
        },
      });
      _setDocumentos([]);
    }
  }, [_apiDocumento, _modal, _navigate, _token]);

  const _filtrarArea = (_listaAreas: Area[]) => ({
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
        <Select
          placeholder="Selecciona o busca un área"
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(_input: any, _option: any) =>
            _option.children.toLowerCase().indexOf(_input.toLowerCase()) >= 0
          }
          filterSort={(_optionA: any, _optionB: any) =>
            _optionA.children
              .toLowerCase()
              .localeCompare(_optionB.children.toLowerCase())
          }
          style={{ width: "250px" }}
          onChange={(_value: any, _option: any) => {
            if (_value) {
              let _usuariosFound: UsuarioDescarga[] = [];
              for (let i = 0; i < _usuariosTemp.length; i++) {
                if (
                  _usuariosTemp[i].documento.subproceso.proceso.area.area
                    .toString()
                    .toUpperCase()
                    .indexOf(_option.children.toUpperCase()) > -1
                ) {
                  _usuariosFound.push(_usuariosTemp[i]);
                }
              }
              _setUsuarios(_usuariosFound);
            } else {
              _setUsuarios(_usuariosTemp);
            }
          }}
        >
          {_listaAreas.map((_area: Area, _index: number) => (
            <Option key={`${_area.codigo}_${_index}`} value={_area.codigo}>
              {_area.area}
            </Option>
          ))}
        </Select>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <FilterOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  const _filtrarSubproceso = (_listaSubprocesos: Subproceso[]) => ({
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
        <Select
          placeholder="Selecciona o busca un subproceso"
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(_input: any, _option: any) =>
            _option.children.toLowerCase().indexOf(_input.toLowerCase()) >= 0
          }
          filterSort={(_optionA: any, _optionB: any) =>
            _optionA.children
              .toLowerCase()
              .localeCompare(_optionB.children.toLowerCase())
          }
          style={{ width: "250px" }}
          onChange={(_value: any, _option: any) => {
            if (_value) {
              let _usuariosFound: UsuarioDescarga[] = [];
              for (let i = 0; i < _usuariosTemp.length; i++) {
                if (
                  _usuariosTemp[i].documento.subproceso.subproceso
                    .toString()
                    .toUpperCase()
                    .indexOf(_option.children.toUpperCase()) > -1
                ) {
                  _usuariosFound.push(_usuariosTemp[i]);
                }
              }
              _setUsuarios(_usuariosFound);
            } else {
              _setUsuarios(_usuariosTemp);
            }
          }}
        >
          {_listaSubprocesos.map((_subproceso: Subproceso, _index: number) => (
            <Option
              key={`${_subproceso.codigo}_${_index}`}
              value={_subproceso.codigo}
            >
              {_subproceso.subproceso}
            </Option>
          ))}
        </Select>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <FilterOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  const _filtrarTipo = (_listaTipos: Tipo[]) => ({
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
        <Select
          placeholder="Selecciona o busca un tipo"
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(_input: any, _option: any) =>
            _option.children.toLowerCase().indexOf(_input.toLowerCase()) >= 0
          }
          filterSort={(_optionA: any, _optionB: any) =>
            _optionA.children
              .toLowerCase()
              .localeCompare(_optionB.children.toLowerCase())
          }
          style={{ width: "250px" }}
          onChange={(_value: any, _option: any) => {
            if (_value) {
              let _usuariosFound: UsuarioDescarga[] = [];
              for (let i = 0; i < _usuariosTemp.length; i++) {
                if (
                  _usuariosTemp[i].documento.tipo.tipo
                    .toString()
                    .toUpperCase()
                    .indexOf(_option.children.toUpperCase()) > -1
                ) {
                  _usuariosFound.push(_usuariosTemp[i]);
                }
              }
              _setUsuarios(_usuariosFound);
            } else {
              _setUsuarios(_usuariosTemp);
            }
          }}
        >
          {_listaTipos.map((_tipo: Tipo, _index: number) => (
            <Option key={`${_tipo.codigo}_${_index}`} value={_tipo.codigo}>
              {_tipo.tipo}
            </Option>
          ))}
        </Select>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <FilterOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  const _filtrarEstado = () => ({
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
        <Select
          placeholder="Selecciona un estado"
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(_input: any, _option: any) =>
            _option.children.toLowerCase().indexOf(_input.toLowerCase()) >= 0
          }
          filterSort={(_optionA: any, _optionB: any) =>
            _optionA.children
              .toLowerCase()
              .localeCompare(_optionB.children.toLowerCase())
          }
          style={{ width: "250px" }}
          onChange={(_value: string, _option: any) => {
            if (_value) {
              let _usuariosFound: UsuarioDescarga[] = [];
              for (let i = 0; i < _usuariosTemp.length; i++) {
                if (_usuariosTemp[i].documento.estado === _value) {
                  _usuariosFound.push(_usuariosTemp[i]);
                }
              }
              _setUsuarios(_usuariosFound);
            } else {
              _setUsuarios(_usuariosTemp);
            }
          }}
        >
          <Option key="estado_vigente" value="VIGENTE">
            VIGENTE
          </Option>
          <Option key="estado_obsoleto" value="OBSOLETO">
            OBSOLETO
          </Option>
        </Select>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <FilterOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  const _onChangeDocumento = (_value: any, _option: any) => {
    _setLoading(true);
    _setUsuarios([]);

    let _usuariosFound: UsuarioDescarga[] = [];
    if (!_value) {
      _setUsuarios([]);
    }

    if (_value === "TODOS") {
      _documentos.forEach((_documento: Documento, _index: number) => {
        _documento.usuarios.forEach(
          (_usuario: UsuarioDescarga, _index: number) => {
            _usuariosFound.push(_usuario);
          }
        );
      });
    }

    let _documento: Documento = _documentos.filter(
      (_documento: Documento, _index: number) => {
        return _documento.codigo === _value;
      }
    )[0];
    if (_documento) {
      _documento.usuarios.forEach(
        (_usuario: UsuarioDescarga, _index: number) => {
          _usuariosFound.push(_usuario);
        }
      );
    }

    _setUsuarios(_usuariosFound);
    _setLoading(false);
  };

  const _descargarReporte = (_data: UsuarioDescarga[]) => {
    const _excel = new Excel();
    _excel
      .addSheet("Reporte de difusión")
      .addColumns([
        {
          title: "Empresa",
          dataIndex: "usuario",
          width: 100,
          render: (_value: Usuario, _row: UsuarioDescarga, _index: number) => {
            return _value.empresa.empresa;
          },
        },
        {
          title: "Sede",
          dataIndex: "usuario",
          width: 100,
          render: (_value: Usuario, _row: UsuarioDescarga, _index: number) => {
            return _value.sede.sede;
          },
        },
        {
          title: "Usuario",
          dataIndex: "usuario",
          width: 200,
          render: (_value: Usuario, _row: UsuarioDescarga, _index: number) => {
            return _value.nombres;
          },
        },
        {
          title: "Puesto",
          dataIndex: "usuario",
          width: 200,
          render: (_value: Usuario, _row: UsuarioDescarga, _index: number) => {
            return _value.puesto.puesto;
          },
        },
        {
          title: "Fecha de publicación",
          dataIndex: "documento",
          width: 150,
          render: (
            _value: Documento,
            _row: UsuarioDescarga,
            _index: number
          ) => {
            return _value.fecha;
          },
        },
        {
          title: "Fecha de vigencia",
          dataIndex: "documento",
          width: 150,
          render: (
            _value: Documento,
            _row: UsuarioDescarga,
            _index: number
          ) => {
            return Moment(_value.fecha).format("DD-MM-YYYY");
          },
        },
        {
          title: "Fecha de revisión",
          dataIndex: "fecha_descarga",
          width: 150,
        },
        {
          title: "Área",
          dataIndex: "documento",
          width: 200,
          render: (
            _value: Documento,
            _row: UsuarioDescarga,
            _index: number
          ) => {
            return _value.subproceso.proceso.area.area;
          },
        },
        {
          title: "Subproceso",
          dataIndex: "documento",
          width: 200,
          render: (
            _value: Documento,
            _row: UsuarioDescarga,
            _index: number
          ) => {
            return _value.subproceso.subproceso;
          },
        },
        {
          title: "Tipo",
          dataIndex: "documento",
          width: 150,
          render: (
            _value: Documento,
            _row: UsuarioDescarga,
            _index: number
          ) => {
            return _value.tipo.tipo;
          },
        },
        {
          title: "Código",
          dataIndex: "documento",
          width: 200,
          render: (
            _value: Documento,
            _row: UsuarioDescarga,
            _index: number
          ) => {
            return _value.documento;
          },
        },
        {
          title: "Versión",
          dataIndex: "documento",
          width: 80,
          render: (
            _value: Documento,
            _row: UsuarioDescarga,
            _index: number
          ) => {
            return _value.version;
          },
        },
        {
          title: "Documento",
          dataIndex: "documento",
          width: 400,
          render: (
            _value: Documento,
            _row: UsuarioDescarga,
            _index: number
          ) => {
            return _value.nombre;
          },
        },
        {
          title: "Estado",
          dataIndex: "documento",
          width: 100,
          align: "center",
          render: (
            _value: Documento,
            _row: UsuarioDescarga,
            _index: number
          ) => {
            return _value.estado;
          },
        },
        {
          title: "Descargado",
          dataIndex: "descargado",
          width: 100,
          render: (_value: number, _row: UsuarioDescarga, _index: number) => {
            return _value === 1 ? "SI" : "NO";
          },
        },
      ])
      .addDataSource(_data, {
        str2Percent: true,
      })
      .saveAs("Reporte de difusión.xlsx");
  };

  useEffect(() => {
    _setMounted(true);
    _listarDocumentos();
    _listarAreas();
    _listarSubprocesos();
    _listarTipos();
    return () => {
      _setMounted(false);
    };
  }, [_listarAreas, _listarDocumentos, _listarSubprocesos, _listarTipos]);

  if (_mounted === null) {
    return <LoadComponent />;
  }

  if (!_mounted) {
    return <ErrorComponent />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Breadcrumb>
            <Breadcrumb.Item>Inicio</Breadcrumb.Item>
            <Breadcrumb.Item>Reportes</Breadcrumb.Item>
            <Breadcrumb.Item>
              <a href="/reporte-difusion">Reporte de difusi&oacute;n</a>
            </Breadcrumb.Item>
          </Breadcrumb>
          <hr />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Title level={3}>Reporte de difusi&oacute;n</Title>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <label>Documento</label>
          <Select
            onChange={_onChangeDocumento}
            placeholder="Selecciona un documento"
            showSearch
            optionFilterProp="children"
            filterOption={(_input: any, _option: any) =>
              _option.children.toLowerCase().indexOf(_input.toLowerCase()) >= 0
            }
            filterSort={(_optionA: any, _optionB: any) =>
              _optionA.children
                .toLowerCase()
                .localeCompare(_optionB.children.toLowerCase())
            }
            className="mb-10"
            style={{ width: "100%" }}
          >
            <Option key="TODOS" value="TODOS">
              TODOS
            </Option>
            {_documentos.map((_documento: Documento, _index: number) => (
              <Option
                key={`${_documento.codigo}_${_index}`}
                value={_documento.codigo}
              >
                {`${_documento.documento} | Versión: ${_documento.version}`}
              </Option>
            ))}
          </Select>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          xxl={24}
          className="mt-10 mb-10"
        >
          <Space>
            <Button
              icon={<ReloadOutlined />}
              className="button-info"
              onClick={() => {
                _reporte(_documentos);
              }}
            >
              Recargar
            </Button>
            <Button
              icon={<DownloadOutlined />}
              className="button-success"
              onClick={() => {
                _descargarReporte(_usuarios);
              }}
            >
              Descargar
            </Button>
          </Space>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Table
            key="reporte-difusion"
            size="small"
            rowKey="codigo"
            bordered
            columns={[
              {
                title: "Empresa",
                dataIndex: "usuario",
                key: "usuario",
                align: "center",
                render: (
                  _value: Usuario,
                  _row: UsuarioDescarga,
                  _index: number
                ) => {
                  return _value.empresa.empresa;
                },
              },
              {
                title: "Sede",
                dataIndex: "usuario",
                key: "usuario",
                align: "center",
                render: (
                  _value: Usuario,
                  _row: UsuarioDescarga,
                  _index: number
                ) => {
                  return _value.sede.sede;
                },
              },
              {
                title: "Usuario",
                dataIndex: "usuario",
                key: "usuario",
                align: "center",
                render: (
                  _value: Usuario,
                  _row: UsuarioDescarga,
                  _index: number
                ) => {
                  return _value.nombres;
                },
              },
              {
                title: "Puesto",
                dataIndex: "usuario",
                key: "usuario",
                align: "center",
                render: (
                  _value: Usuario,
                  _row: UsuarioDescarga,
                  _index: number
                ) => {
                  return _value.puesto.puesto;
                },
              },
              {
                title: "Fecha de publicación",
                dataIndex: "documento",
                key: "documento",
                align: "center",
                render: (
                  _value: Documento,
                  _row: UsuarioDescarga,
                  _index: number
                ) => {
                  return Moment(_value.fecha).format("DD-MM-YYYY");
                },
              },
              {
                title: "Fecha de vigencia",
                dataIndex: "documento",
                key: "documento",
                align: "center",
                render: (
                  _value: Documento,
                  _row: UsuarioDescarga,
                  _index: number
                ) => {
                  return Moment(_value.vigencia).format("DD-MM-YYYY");
                },
              },
              {
                title: "Fecha de revisión",
                dataIndex: "fecha_descarga",
                key: "fecha_descarga",
                align: "center",
                render: (
                  _value: Date,
                  _row: UsuarioDescarga,
                  _index: number
                ) => {
                  if (_value) {
                    return Moment(_value).format("DD-MM-YYYY");
                  }
                },
              },
              {
                title: "Área",
                dataIndex: "documento",
                key: "documento",
                align: "center",
                ..._filtrarArea(_listaAreas),
                render: (
                  _value: Documento,
                  _row: UsuarioDescarga,
                  _index: number
                ) => {
                  return _value.subproceso.proceso.area.area;
                },
              },
              {
                title: "Subproceso",
                dataIndex: "documento",
                key: "documento",
                align: "center",
                ..._filtrarSubproceso(_listaSubprocesos),
                render: (
                  _value: Documento,
                  _row: UsuarioDescarga,
                  _index: number
                ) => {
                  return _value.subproceso.subproceso;
                },
              },
              {
                title: "Tipo",
                dataIndex: "documento",
                key: "documento",
                align: "center",
                ..._filtrarTipo(_listaTipos),
                render: (
                  _value: Documento,
                  _row: UsuarioDescarga,
                  _index: number
                ) => {
                  return _value.tipo.tipo;
                },
              },
              {
                title: "Código",
                dataIndex: "documento",
                key: "documento",
                align: "center",
                render: (
                  _value: Documento,
                  _row: UsuarioDescarga,
                  _index: number
                ) => {
                  return _value.documento;
                },
              },
              {
                title: "Versión",
                dataIndex: "documento",
                key: "documento",
                align: "center",
                render: (
                  _value: Documento,
                  _row: UsuarioDescarga,
                  _index: number
                ) => {
                  return _value.version;
                },
              },
              {
                title: "Documento",
                dataIndex: "documento",
                key: "documento",
                align: "center",
                render: (
                  _value: Documento,
                  _row: UsuarioDescarga,
                  _index: number
                ) => {
                  return _value.nombre;
                },
              },
              {
                title: "Estado",
                dataIndex: "documento",
                key: "documento",
                align: "center",
                ..._filtrarEstado(),
                render: (
                  _value: Documento,
                  _row: UsuarioDescarga,
                  _index: number
                ) => {
                  return _value.estado;
                },
              },
            ]}
            pagination={{
              pageSize: 20,
              simple: false,
            }}
            loading={_loading}
            dataSource={_usuarios.map((_usuario: UsuarioDescarga) => ({
              ..._usuario,
            }))}
          />
        </Col>
      </Row>
      {_contextHolder}
    </Fragment>
  );
}

export default ReporteDifusionPage;
