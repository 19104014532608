import axios from "axios";
import { Key } from "react";
import Global from "../global/global";
import { APIResponse } from "../model/apiresponse";
import { Documento } from "../model/documento";

export class APIPermiso {
  async _registrarPermiso(
    _token: string,
    _documento: Documento,
    _usuarios: Key[]
  ): Promise<APIResponse> {
    let _response: APIResponse = new APIResponse({});
    try {
      let _result: any = await axios.post(`${Global.URL_API}registrarPermiso`, {
        token: _token,
        usuarios: _usuarios,
        area: _documento.subproceso.proceso.area.codigo,
        proceso: _documento.subproceso.proceso.codigo,
        subproceso: _documento.subproceso.codigo,
        documento: _documento.codigo,
      });
      if (_result.status === 200) {
        _response = {
          success: _result.data.success,
          message: _result.data.message,
          codigo: _result.data.codigo,
        };
      } else {
        _response = {
          success: false,
          message: "Ocurrió un error de conexión, inténtalo nuevamente",
          codigo: _result.data.codigo,
        };
      }
    } catch (e: any) {
      _response = {
        success: false,
        message: e,
      };
    }
    return _response;
  }
}
