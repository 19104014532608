import { Archivo } from "./archivo";
import { Empresa } from "./empresa";
import { Subproceso } from "./subproceso";
import { Tipo } from "./tipo";
import { UsuarioDescarga } from "./usuario-descarga";
export interface DocumentoDataI {
  codigo: number;
  documento: string;
  version: string;
  nombre: string;
  estado: string;
  fecha: string;
  vigencia: string;
  tipo: Tipo;
  archivos: Archivo[];
  subproceso: Subproceso;
  empresa: Empresa,
  usuarios: UsuarioDescarga[];
}

export class Documento implements DocumentoDataI {
  codigo: number;
  documento: string;
  version: string;
  nombre: string;
  estado: string;
  fecha: string;
  vigencia: string;
  tipo: Tipo;
  archivos: Archivo[];
  subproceso: Subproceso;
  empresa: Empresa;
  usuarios: UsuarioDescarga[];

  constructor(data: DocumentoDataI) {
    this.codigo = data.codigo;
    this.documento = data.documento;
    this.version = data.version;
    this.nombre = data.nombre;
    this.estado = data.estado;
    this.fecha = data.fecha;
    this.vigencia = data.vigencia;
    this.tipo = data.tipo;
    this.archivos = data.archivos;
    this.subproceso = data.subproceso;
    this.empresa = data.empresa;
    this.usuarios = data.usuarios;
  }
}
