import { APIResponse } from "../../model/apiresponse";
import { APISubproceso } from "../../api/api_subproceso";
import { Button } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Col } from "antd";
import { Form } from "antd";
import { Fragment } from "react";
import { Input } from "antd";
import { Modal } from "antd";
import { Proceso } from "../../model/proceso";
import { Row } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { Select } from "antd";
import { Space } from "antd";
import { Spin } from "antd";
import { useState } from "react";
import Global from "../../global/global";

function SubprocesosRegistrarWidget({
  _listaProcesos,
  _listarSubprocesos,
  _cerrarNuevo,
  _apiSubproceso,
  _token,
}: {
  _listaProcesos: Proceso[];
  _listarSubprocesos: Function;
  _cerrarNuevo: Function;
  _apiSubproceso: APISubproceso;
  _token: string;
}) {
  const [_form] = Form.useForm();
  const [_load, _setLoad] = useState<boolean>(false);
  const [_modal, _contextHolder] = Modal.useModal();
  const { Option } = Select;

  const _registrarSubproceso = (_values: any) => {
    _setLoad(true);
    _modal.confirm({
      title: Global.NOMBRE_PROYECTO,
      content: "¿Está seguro de registrar el subproceso?",
      centered: true,
      okText: "Si, registrar",
      cancelText: "No, cancelar",
      onCancel: () => {
        _setLoad(false);
      },
      onOk: async () => {
        let _result: APIResponse = await _apiSubproceso._registrarSubproceso(
          _values["subproceso"],
          _values["proceso"],
          _token
        );
        _setLoad(false);
        if (_result.success) {
          _modal.success({
            title: Global.NOMBRE_PROYECTO,
            content: _result.message,
            centered: true,
            onOk: () => {
              _form.resetFields();
              _listarSubprocesos();
              _cerrarNuevo();
            },
          });
        } else {
          _modal.error({
            title: Global.NOMBRE_PROYECTO,
            content: _result.message,
            centered: true,
          });
        }
      },
    });
  };

  return (
    <Fragment>
      <Form
        name="form-nuevo-subproceso"
        className="form-nuevo-subproceso"
        layout="vertical"
        onFinish={_registrarSubproceso}
        autoComplete="off"
        form={_form}
      >
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Form.Item
              label="Nombre"
              name="subproceso"
              rules={[
                {
                  required: true,
                  message: "¡Por favor ingresa el nombre del subproceso!",
                },
              ]}
            >
              <Input allowClear size="middle" readOnly={_load ? true : false} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Form.Item
              label="Proceso"
              name="proceso"
              rules={[
                {
                  required: true,
                  message: "¡Por favor selecciona un proceso!",
                },
              ]}
            >
              <Select
                placeholder="Elija una opción"
                allowClear
                showSearch
                optionFilterProp="children"
                filterOption={(_input: any, _option: any) =>
                  _option.children
                    .toLowerCase()
                    .indexOf(_input.toLowerCase()) >= 0
                }
                filterSort={(_optionA: any, _optionB: any) =>
                  _optionA.children
                    .toLowerCase()
                    .localeCompare(_optionB.children.toLowerCase())
                }
              >
                {_listaProcesos.map((_proceso: Proceso, _index: number) => (
                  <Option
                    key={`${_proceso.codigo}_${_index}`}
                    value={_proceso.codigo}
                  >
                    {_proceso.proceso}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            xxl={24}
            className="center-text mt-10"
          >
            <Form.Item>
              {_load ? (
                <Spin className="spin-success" tip="Registrando..." />
              ) : (
                <Space>
                  <Button
                    htmlType="submit"
                    className="button-success"
                    icon={<SaveOutlined />}
                  >
                    Registrar
                  </Button>
                  <Button
                    className="button-danger"
                    icon={<CloseCircleOutlined />}
                    onClick={() => {
                      _cerrarNuevo();
                    }}
                  >
                    Cancelar
                  </Button>
                </Space>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {_contextHolder}
    </Fragment>
  );
}

export default SubprocesosRegistrarWidget;
