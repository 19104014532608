import "../../styles/pages/dashboard.page.scss";
import { BankOutlined } from "@ant-design/icons";
import { DeploymentUnitOutlined } from "@ant-design/icons";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";
import { FileDoneOutlined } from "@ant-design/icons";
import { Fragment } from "react";
import { Layout } from "antd";
import { Menu } from "antd";
import { Modal } from "antd";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import { PartitionOutlined } from "@ant-design/icons";
import { PoweroffOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useCallback } from "react";
import { useEffect } from "react";
import { LockFilled } from "@ant-design/icons";
import { BarChartOutlined } from "@ant-design/icons";
import { useState } from "react";
import { Usuario } from "../../model/usuario";
import ErrorComponent from "../components/error.component";
import Global from "../../global/global";
import LoadComponent from "../components/load.component";
import logo from "../../assets/logo_blanco.svg";

type ContextType = { _usuario: Usuario; _token: string };
export function _getSession() {
  return useOutletContext<ContextType>();
}

function DashboardPage() {
  const [_collapsed, _setCollapsed] = useState<boolean>(true);
  const [_modal, _contextHolder] = Modal.useModal();
  const [_mounted, _setMounted] = useState<any>(null);
  const [_path, _setPath] = useState("");
  const [_usuario, _setUsuario] = useState<Usuario>();
  const [_token, _setToken] = useState<string>();
  const { Header } = Layout;
  const { Content } = Layout;
  const { Footer } = Layout;
  const { Sider } = Layout;
  let _navigate = useNavigate();

  const _getRoute = useCallback(() => {
    let _data: string[] = window.location.pathname.split("/");
    if (_data && _data.length > 0) {
      let _path = _data[_data.length - 1];
      _setPath(_path === "" ? "areas" : _path);
    }
  }, []);

  const _getCurrentUser = useCallback(async () => {
    if (
      localStorage.getItem(Global.USUARIO_SESSION) &&
      localStorage.getItem(Global.TOKEN)
    ) {
      _setUsuario(
        new Usuario(JSON.parse(localStorage.getItem(Global.USUARIO_SESSION)!))
      );
      _setToken(localStorage.getItem(Global.TOKEN)!);
    } else {
      _navigate("/login");
    }
  }, [_navigate]);

  const _onCollapse = (_collapsed: boolean) => {
    _setCollapsed(_collapsed);
  };

  const _logout = () => {
    _modal.confirm({
      title: Global.NOMBRE_PROYECTO,
      content: "¿Está seguro de cerrar su sesión?",
      okText: "Si, cerrar",
      cancelText: "No, cancelar",
      onOk: () => {
        localStorage.clear();
        _navigate("/login");
      },
      centered: true,
    });
  };

  useEffect(() => {
    _setMounted(true);
    _getRoute();
    _getCurrentUser();
    return () => {
      _setMounted(false);
    };
  }, [_getCurrentUser, _getRoute]);

  if (_mounted === null) {
    return <LoadComponent />;
  }

  if (!_mounted) {
    return <ErrorComponent />;
  }

  return (
    <Fragment>
      <Layout id="layout-main">
        <Sider collapsible collapsed={_collapsed} onCollapse={_onCollapse}>
          <img id="img-logo" src={logo} alt="3AAMSEQ" title="3AAMSEQ" />
          <Menu
            theme="dark"
            selectedKeys={[_path]}
            mode="inline"
            items={[
              {
                key: "areas",
                icon: <BankOutlined />,
                title: "Áreas",
                label: "Áreas",
                onClick: () => {
                  _navigate("/areas");
                },
              },
              {
                key: "procesos",
                icon: <DeploymentUnitOutlined />,
                title: "Procesos",
                label: "Procesos",
                onClick: () => {
                  _navigate("/procesos");
                },
              },
              {
                key: "subprocesos",
                icon: <PartitionOutlined />,
                title: "Subprocesos",
                label: "Subprocesos",
                onClick: () => {
                  _navigate("/subprocesos");
                },
              },
              {
                key: "documentos",
                icon: <FileDoneOutlined />,
                title: "Documentos",
                label: "Documentos",
                onClick: () => {
                  _navigate("/documentos");
                },
              },
              {
                key: "permisos",
                icon: <LockFilled />,
                title: "Permisos",
                label: "Permisos",
                onClick: () => {
                  _navigate("/permisos");
                },
              },
              {
                key: "seccion-reportes",
                icon: <BarChartOutlined />,
                title: "Reportes",
                label: "Reportes",
                children: [
                  {
                    key: "reporte-difusion",
                    title: "Reporte de difusión",
                    label: "Reporte de difusión",
                    onClick: () => {
                      _navigate("/reporte-difusion");
                    },
                  },
                ],
              },
            ]}
          />
        </Sider>
        <Layout>
          <Header id="header">
            <Dropdown.Button
              className="button-logout"
              overlay={
                <Menu onClick={_logout}>
                  <Menu.Item key="1" icon={<PoweroffOutlined />}>
                    Cerrar sesi&oacute;n
                  </Menu.Item>
                </Menu>
              }
              placement="bottomLeft"
              icon={<DownOutlined />}
              trigger={["click"]}
            >
              {_usuario!.nombres ?? <Spin className="spin-success" />}
            </Dropdown.Button>
          </Header>
          <Content id="content">
            <Outlet context={{ _usuario, _token }} />
          </Content>
          <Footer id="footer">
            {Global.NOMBRE_PROYECTO} ©{new Date().getFullYear()} Creado por TI
          </Footer>
        </Layout>
      </Layout>
      {_contextHolder}
    </Fragment>
  );
}

export default DashboardPage;
