import { Proceso } from "./proceso";
export interface SubprocesoDataI {
  codigo: number;
  subproceso: string;
  estado: number;
  proceso: Proceso;
}

export class Subproceso implements SubprocesoDataI {
  codigo: number;
  subproceso: string;
  estado: number;
  proceso: Proceso;

  constructor(data: SubprocesoDataI) {
    this.codigo = data.codigo;
    this.subproceso = data.subproceso;
    this.estado = data.estado;
    this.proceso = data.proceso;
  }
}
