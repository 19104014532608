import { APIArea } from "../../api/api_area";
import { APIResponse } from "../../model/apiresponse";
import { Button } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Col } from "antd";
import { Form } from "antd";
import { Fragment } from "react";
import { Input } from "antd";
import { Modal } from "antd";
import { Row } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { Space } from "antd";
import { Spin } from "antd";
import { useState } from "react";
import Global from "../../global/global";

function AreasRegistrarWidget({
  _listarAreas,
  _cerrarNuevo,
  _apiArea,
  _token,
}: {
  _listarAreas: Function;
  _cerrarNuevo: Function;
  _apiArea: APIArea;
  _token: string;
}) {
  const [_form] = Form.useForm();
  const [_load, _setLoad] = useState<boolean>(false);
  const [_modal, _contextHolder] = Modal.useModal();

  const _registrarArea = (_values: any) => {
    _setLoad(true);
    _modal.confirm({
      title: Global.NOMBRE_PROYECTO,
      content: "¿Está seguro de registrar el área?",
      centered: true,
      okText: "Si, registrar",
      cancelText: "No, cancelar",
      onCancel: () => {
        _setLoad(false);
      },
      onOk: async () => {
        let _result: APIResponse = await _apiArea._registrarArea(
          _values["area"],
          _token
        );
        _setLoad(false);
        if (_result.success) {
          _modal.success({
            title: Global.NOMBRE_PROYECTO,
            content: _result.message,
            centered: true,
            onOk: () => {
              _form.resetFields();
              _listarAreas();
              _cerrarNuevo();
            },
          });
        } else {
          _modal.error({
            title: Global.NOMBRE_PROYECTO,
            content: _result.message,
            centered: true,
          });
        }
      },
    });
  };

  return (
    <Fragment>
      <Form
        name="form-nueva-area"
        className="form-nueva-area"
        layout="vertical"
        onFinish={_registrarArea}
        autoComplete="off"
        form={_form}
      >
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Form.Item
              label="Nombre"
              name="area"
              rules={[
                {
                  required: true,
                  message: "¡Por favor ingresa el nombre del área!",
                },
              ]}
            >
              <Input allowClear size="middle" readOnly={_load ? true : false} />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            xxl={24}
            className="center-text mt-10"
          >
            <Form.Item>
              {_load ? (
                <Spin className="spin-success" tip="Registrando..." />
              ) : (
                <Space>
                  <Button
                    htmlType="submit"
                    className="button-success"
                    icon={<SaveOutlined />}
                  >
                    Registrar
                  </Button>
                  <Button
                    className="button-danger"
                    icon={<CloseCircleOutlined />}
                    onClick={() => {
                      _cerrarNuevo();
                    }}
                  >
                    Cancelar
                  </Button>
                </Space>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {_contextHolder}
    </Fragment>
  );
}

export default AreasRegistrarWidget;
