import axios from "axios";
import Global from "../global/global";
import { APIResponse } from "../model/apiresponse";
import { Puesto } from "../model/puesto";
import { PuestoDataI } from "../model/puesto";

export class APIPuesto {
  async _listarPuestos(_token: string): Promise<APIResponse> {
    let _response: APIResponse = new APIResponse({});
    try {
      let _result: any = await axios.post(`${Global.URL_API}listarPuestos`, {
        token: _token,
      });
      if (_result.status === 200) {
        if (_result.data.success) {
          let _puestos: Puesto[] = [];
          _result.data.data.forEach((_puesto: PuestoDataI) => {
            _puestos.push(new Puesto(_puesto));
          });
          _response = {
            success: _result.data.success,
            data: _puestos,
            codigo: _result.data.codigo,
          };
        } else {
          _response = {
            success: _result.data.success,
            message: _result.data.message,
            codigo: _result.data.codigo,
          };
        }
      } else {
        _response = {
          success: false,
          message: "Ocurrió un error de conexión, inténtalo nuevamente",
          codigo: _result.data.codigo,
        };
      }
    } catch (e: any) {
      _response = {
        success: false,
        message: e,
      };
    }
    return _response;
  }
}
