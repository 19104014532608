export interface SedeDataI {
  codigo: number;
  sede: string;
  estado: number;
}

export class Sede implements SedeDataI {
  codigo: number;
  sede: string;
  estado: number;

  constructor(data: SedeDataI) {
    this.codigo = data.codigo;
    this.sede = data.sede;
    this.estado = data.estado;
  }
}
